import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import {
    useGetIsPermissionGrantedQuery,
    useGrantPermissionMutation,
    useRemovePermissionMutation,
} from "../user-manager/UserDetailAccessApi";

export const PermissionToAccessCohortDownloading = ({ userDetailsId }) => {
    const { t: translate } = useTranslation();
    const {
        data: canExportCohort,
        isFetching,
    } = useGetIsPermissionGrantedQuery({ userDetailsId, permissionType: "COHORT_EXPORT" }, { skip: !userDetailsId });
    const [grantPermission] = useGrantPermissionMutation();
    const [removePermission] = useRemovePermissionMutation();
    const toggleCohortPermission = (userDetailsId) => {
        canExportCohort
            ? removePermission({ userDetailsId, permissionType: "COHORT_EXPORT" })
            : grantPermission({ userDetailsId, permissionType: "COHORT_EXPORT" });
    };
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={canExportCohort || false}
                    onClick={() => {
                        toggleCohortPermission(userDetailsId);
                    }}
                    disabled={isFetching || !userDetailsId}
                    color={"primary"}
                />
            }
            label={translate("global.allow-cohort-export")}
        />
    );
};
