import React, {Component} from 'react';
import {
    Typography,
    Button,
    MenuItem,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import {withTranslation} from 'react-i18next';
import Flex from 'components/grid/Flex';
import * as colors from 'components/colors/Colors';
import utils from 'utils/Utils';
import MultiInput from 'components/input/MultiInput';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import Subordinate from './Subordinate';
import HmoBorderedButton from 'components/button/HmoBorderedButton';
import PersonIcon from '@material-ui/icons/Person';
import Dropzone from 'react-dropzone';
import Resizer from "react-image-file-resizer";
import HmoAvatar from 'components/avatar/HmoAvatar';
import EpiAlert from 'components/alert/EpiAlert';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import Accordion from 'components/grid/Accordion';
import UserManager from 'scenes/user-manager/UserManager';
import AccessManager from 'scenes/user-manager/access-manager/AccessManager';
import {connect} from 'react-redux';
import connector from './Contact.connect';
import NewContactDialog from './NewContactDialog';
import update from 'immutability-helper';
import MultiInputEmailAddressEditor from 'scenes/user-manager/access-manager/MultiInputEmailAddressEditor';
import {DOCTOR} from 'domain/User.model';
import {CARE_PROVIDER} from 'domain/EntityAuthorizationType.model';
import { PermissionToAccessCohortDownloading } from './PermissionToAccessCohortDownloading';

export class Contact extends Component {

    state = {
        contact: {},
        oldContact: {},
        isDeleteDialogOpen: false,
        newImage: '',
        isNewSubordinateDialogOpen: false
    }

    componentDidMount() {
        this.reset();
    }

    reset = () => {
        return new Promise(resolve => {
            if (this.props.userDetailsId) {
                this.props.getUserDetails(this.props.userDetailsId)
                        .then(response => {
                            this.setState({
                                contact: response?.payload?.data,
                                oldContact: response?.payload?.data
                            }, ()=> resolve());
                        });
            } else {
                resolve();
            }
        })
    }

    save = event => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.props.updateUserDetails(this.state.contact).then(()=>{
            this.reset().then(()=> {
                this.props.refresh();
            });
        });
    }

    changeState = event => {
        this.setState({
            contact: update(this.state.contact, {
                [event.target.name]: { $set: event.target.value },
            }),
        });
    }

    handlePhoneNumbersChange = (props) => {
        this.changeState({
            target: {
                name: 'phoneNumbers',
                value: props.phoneNumbers
            }
        });
    }

    handleFaxNumbersChange = (props) => {
        this.changeState({
            target: {
                name: 'faxNumbers',
                value: props.faxNumbers
            }
        });
    }

    handleEmailAddressesChange = (props) => {
        this.changeState({
            target: {
                name: 'emailAddresses',
                value: props.emailAddresses
            }
        });
    }

    toggleDeleteDialog = () => {
        this.setState({
            isDeleteDialogOpen: !this.state.isDeleteDialogOpen
        });
    }

    deleteUserDetails = () => {
        this.props.deleteUserDetails(this.state.contact.id).then(()=>{
            this.props.refresh();
        });
    }

    addSubordinate = () => {
        this.props.newContact(this.state.contact.businessId);
    }

    onDrop = (acceptedFiles) => {
        if (acceptedFiles) {
            try {
                Resizer.imageFileResizer(
                        acceptedFiles[0],
                        300,
                        300,
                        "JPEG",
                        100,
                        0,
                        file => {
                            this.props.uploadContactImage(this.state.contact.businessId, file).then(()=>{
                                this.reset();
                            })
                        },
                        "file",
                        200,
                        200
                );
            } catch (err) {
                console.log(err);
                this.setState({alert: 'not-an-image'});
            }
        }
    };

    closeSnackbar = () => {
        this.setState({alert: ''});
    }

    toggleIsNewSubordinateDialogOpen = () => {
        this.setState({isNewSubordinateDialogOpen: !this.state.isNewSubordinateDialogOpen});
    }

    render() {
        const {
            t: translate, contacts, configuration, careProviderId, refresh, excludedUserIds, existingInvitesForEntity
        } = this.props;
        const {
            changeState, toggleDeleteDialog, deleteUserDetails, save, handleFaxNumbersChange, onDrop,
            toggleIsNewSubordinateDialogOpen
        } = this;
        const {isDeleteDialogOpen, contact, isNewSubordinateDialogOpen} = this.state;
        const isContactChanged = utils.anyPropertyChanged(this.state.oldContact, contact);
        const subordinates = contacts.filter(contactInArray => contactInArray.subordinateToBusinessId === contact.businessId);
        const invitedRoleTypeOfCareManager = contacts.find(item => item.id === contact.id)?.invitedRoleTypeOfCareManager;

        return (
                <div data-testid={`contact:${contact?.userEmailAddress}`} style={{margin: 10, maxWidth: '48%', width: '50%'}}>
                    <Accordion openByDefault={!contact.givenName} titleComponent={
                        <Flex item container justifyContent={'space-between'} alignItems={'center'}>
                            <Typography style={{
                                fontWeight: 'bold'
                            }}>
                                {translate(`global.${invitedRoleTypeOfCareManager || 'contact'}`)}: {utils.formattedUserName(contact)}
                            </Typography>

                            <Flex item={'0 1 auto'} container alignItems={'center'}>
                                {
                                    contact?.userBusinessId
                                            ? <UserManager {...{
                                                userBusinessId: contact?.userBusinessId,
                                                emailAddresses: contact?.emailAddresses
                                            }}/>
                                            : <AccessManager {...{
                                                patient: null,
                                                person: contact,
                                                invitedRoleType: invitedRoleTypeOfCareManager,
                                                excludedUserIds,
                                                existingInvitesForEntity,
                                                buttonStyle: {height: 30},
                                                beforeOpen: save,
                                                callback: refresh,
                                                entityIdToAssign: careProviderId,
                                                entityType: CARE_PROVIDER
                                            }}/>
                                }
                                <Button size={'small'} variant={'outlined'} onClick={toggleDeleteDialog}
                                        style={{margin: '0px 10px'}}>
                                    {translate('global.delete')}
                                </Button>
                                {
                                    isContactChanged &&
                                    <Button data-testid="save-contact" size={'small'}
                                            style={{backgroundColor: 'orange'}}
                                            onClick={save}>
                                        {translate('global.save')}
                                    </Button>
                                }
                            </Flex>
                        </Flex>
                    }>
                        <Flex container column>
                            <Flex id={'first-line'} item container wrap>
                                <Flex item container>
                                    <DefaultTextField data-testid={'salutation-picker'} label={translate("case.salutation")}
                                                      style={{width: '100%'}}
                                                      select
                                                      SelectProps={{
                                                          value: contact.salutation || '',
                                                          name: 'salutation',
                                                          onChange: changeState
                                                      }}>
                                        {configuration.salutations && configuration.salutations.map(salutation => (
                                                <MenuItem data-testid={`contact:${contact?.userEmailAddress}:${salutation}`} key={salutation} value={salutation}>
                                                    {translate(`global.${salutation}`)}
                                                </MenuItem>
                                        ))}
                                    </DefaultTextField>
                                    <DefaultTextField value={contact.title || ''}
                                                      name={'title'}
                                                      style={{width: '100%'}}
                                                      label={translate("case.title")}
                                                      onChange={changeState}/>
                                </Flex>
                                <Flex item container>
                                    <DefaultTextField value={contact.givenName || ''}
                                                      label={translate("case.first-name")}
                                                      style={{width: '100%'}}
                                                      name={'givenName'}
                                                      onChange={changeState}/>

                                    <DefaultTextField label={translate("case.last-name")}
                                                      value={contact.familyName || ''}
                                                      onChange={changeState}
                                                      style={{width: '100%'}}
                                                      name={'familyName'}/>

                                </Flex>
                            </Flex>
                            <Flex id={'image-line'} item container>
                                <div style={{height: 150, width: 150, paddingRight: 10, paddingBottom: 10}}>
                                    {
                                        contact.avatarDownloadUrl &&
                                        <HmoAvatar user={contact} style={{height: 140, width: 140}}/>
                                    }
                                    {
                                        !contact.avatarDownloadUrl &&
                                        <Dropzone onDrop={onDrop}>
                                            {({getRootProps, getInputProps, isDragActive}) => {
                                                return (
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <PersonIcon style={{
                                                                height: 140, width: 140, borderRadius: '50%',
                                                                fill: colors.persianGreen
                                                            }}/>
                                                        </div>
                                                )
                                            }}
                                        </Dropzone>
                                    }
                                </div>
                                <Flex item container column>
                                    <DefaultTextField label={translate("global.occupation")}
                                                      value={contact.occupation || ''}
                                                      onChange={changeState}
                                                      name={'occupation'}/>
                                                      <PermissionToAccessCohortDownloading userDetailsId={contact.id} />
                                </Flex>

                            <div>
                            </div>
                            </Flex>

                            <Flex id={'third-line'} item container>
                                <Flex item container column style={{marginRight: 10}}>
                                    <MultiInput {...{
                                        inputValues: contact.phoneNumbers,
                                        translate,
                                        name: 'phoneNumbers',
                                        updateInputValues: this.handlePhoneNumbersChange
                                    }}/>
                                </Flex>
                                <Flex item container column>
                                    {
                                        contact.userEmailAddress && !contact.emailAddresses.includes(contact.userEmailAddress) &&
                                        <DefaultTextField value={contact.userEmailAddress || ''}
                                                          label={translate("global.user-email-address")}
                                                          disabled
                                                          onChange={() => {
                                                          }}/>
                                    }
                                    <MultiInputEmailAddressEditor {...{
                                        entityIdToAssign: careProviderId,
                                        entityType: CARE_PROVIDER,
                                        emailAddresses: contact.emailAddresses,
                                        invitedRoleType: DOCTOR,
                                        person: contact,
                                        updateInputValues: this.handleEmailAddressesChange,
                                        refresh
                                    }}/>
                                </Flex>
                            </Flex>
                            <Flex id={'forth-line'} item container column>
                                <MultiInput {...{
                                    inputValues: contact.faxNumbers,
                                    translate,
                                    name: 'faxNumbers',
                                    updateInputValues: handleFaxNumbersChange
                                }}/>
                            </Flex>
                            <Flex id={'fifth-line'} item container>
                                <DefaultTextField
                                        label={translate('global.comment')}
                                        multiline
                                        minRows={3}
                                        style={{width: '100%'}}
                                        value={contact.comment || ''}
                                        onChange={changeState}
                                        name={'comment'}
                                />
                            </Flex>
                            {
                                subordinates && (subordinates || []).map(subordinate => (
                                        <Subordinate key={`${subordinate.businessId} - index`} {...{
                                            userDetailsId: subordinate.id,
                                            careProviderId,
                                            refresh,
                                            style: {
                                                marginTop: 10,
                                                marginRight: 7
                                            }
                                        }}/>
                                ))
                            }
                            <Flex item grow={0} container justifyContent={'center'} style={{margin: 10}}>
                                <HmoBorderedButton style={{flex: 1}} onClick={toggleIsNewSubordinateDialogOpen} size={'small'}>
                                    + {translate('global.add-secretary')}
                                </HmoBorderedButton>
                            </Flex>
                            {
                                isNewSubordinateDialogOpen &&
                                <NewContactDialog {...{
                                    careProviderId,
                                    refreshContacts: ()=>{
                                        refresh();
                                        toggleIsNewSubordinateDialogOpen();
                                        },
                                    subordinateToBusinessId: contact.businessId,
                                    cancel: toggleIsNewSubordinateDialogOpen}}/>
                            }
                        </Flex>
                    </Accordion>
                    <ConfirmationDialog {...{
                        dialogOpen: isDeleteDialogOpen,
                        onDialogClose: toggleDeleteDialog,
                        onConfirm: deleteUserDetails,
                        confirmationTextKey: "clinic.delete-contact",
                        title: translate("clinic.delete-contact-title"),
                    }}/>
                    <EpiAlert
                            {...{
                                isOpen: !!this.state.alert,
                                close: this.closeSnackbar,
                                severity: this.state.alert === 'error' ? this.state.alert : 'success',
                                message:
                                        this.state.alert === 'error'
                                                ? translate('global.backend-call-failed')
                                                : this.state.alert
                                                        ? translate(`global.${this.state.alert}`)
                                                        : '',
                            }}
                    />
                </div>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(Contact));
