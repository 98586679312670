import React, {Component, Fragment} from "react";
import {
    withStyles,
    Typography,
    IconButton,
    Dialog,
    Select,
    MenuItem,
    ListItemText,
    CircularProgress,
    InputAdornment,
    Tooltip
} from "@material-ui/core";
import {Close as CloseIcon, Check as CheckIcon, Error} from "@material-ui/icons";
import Flex from "components/grid/Flex";
import {grey, htmlWhite} from "components/colors/Colors";
import {withTranslation} from "react-i18next";
import utils from "utils/Utils";
import HmoInputLabel from "components/input-label/HmoInputLabel";
import HmoTextField from "components/hmo-textfield/HmoTextField";
import update from "immutability-helper";

const styles = (theme) => ({
    inputRoot: {
        backgroundColor: htmlWhite,
        borderRadius: 4,
        paddingLeft: 7,
    },
});

function preventDots(event) {
    if (event.key.charCodeAt(0) === 46) {
        event.preventDefault();
        event.stopPropagation();
    }
}

const DocumentHeader = (props) => {
    const {translate} = props;
    const colWidth = props.withAssignmentColumn ? "33.33%" : "50%";
    return (
            <Flex item container>
                <HmoInputLabel style={{width: colWidth, marginRight: 10}}>
                    {translate("case.descriptive-name")}
                </HmoInputLabel>
                <HmoInputLabel style={{width: colWidth, marginRight: 10}}>{translate("case.type")}</HmoInputLabel>
                {props.withAssignmentColumn &&
                <HmoInputLabel style={{width: colWidth}}>{translate("documentUpload.assignment")}</HmoInputLabel>}
            </Flex>
    );
};

const DocumentRow = (props) => {
    const {
        classes,
        translate,
        index,
        handleFileNameChange,
        handleDocumentTypeChange,
        fileNameWithoutExtension,
        documentTypes,
        fileExtension,
        documentType,
        isUploadInProgress,
        isUploadDone,
        isVirusFound,
        assignmentTypes,
        assignmentType,
        documentAssignment,
        isError,
        handleAssignmentTypeChange,
    } = props;
    const renderAssignmentValue = (assignment, translate) => {
        switch (assignment.name) {
            case "PATIENT":
                return translate('global.general-medical-files');
            case "CASE":
                return translate('global.second-opinion');
            default:
                return assignment.name;
        }
    };
    return (
            <Flex item container padding={3}>
                {
                    isUploadDone &&
                    <Flex item grow={0} container padding={3} alignItems={'center'}>
                        {
                            isVirusFound &&
                            <Tooltip title={translate("global.virus-found")}>
                                <Error style={{fill: 'red'}}/>
                            </Tooltip>
                        }
                        {
                            isError &&
                            <Tooltip title={translate("global.error")}>
                                <Error style={{fill: 'grey'}}/>
                            </Tooltip>
                        }
                        {
                            !isVirusFound && !isError &&
                            <CheckIcon/>
                        }
                    </Flex>
                }

                <Flex item container style={{width: "33.33%", marginRight: 10}} alignItems={"flex-end"}>
                    <HmoTextField
                            value={fileNameWithoutExtension || ""}
                            onChange={handleFileNameChange(index)}
                            disabled={isUploadInProgress || isUploadDone}
                            onKeyPress={preventDots}
                            placeholder={translate("case.document-upload-name-placeholder")}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                        <InputAdornment
                                                position="start">{fileExtension && `.${fileExtension}`}</InputAdornment>
                                ),
                            }}
                            name={"fileName"}
                    />
                </Flex>
                <Flex item container style={{width: "33.33%", marginRight: 10}}>
                    <Select
                            data-test={"document-type-select"}
                            value={documentType || ""}
                            classes={{root: classes.inputRoot}}
                            onChange={handleDocumentTypeChange(index)}
                            fullWidth
                            disabled={isUploadInProgress || isUploadDone}
                            name={"documentType"}
                            renderValue={(value) => value.longName}
                    >
                        {documentTypes &&
                        documentTypes.map((fileTypeInArray) => (
                                <MenuItem aria-label={fileTypeInArray.enumName} key={fileTypeInArray.enumName} value={fileTypeInArray}>
                                    <ListItemText primary={fileTypeInArray.longName}/>
                                </MenuItem>
                        ))}
                    </Select>
                </Flex>
                {assignmentTypes && (
                        <Flex item container style={{width: "33.33%"}}>
                            <Select
                                    value={documentAssignment || assignmentType || ""}
                                    classes={{root: classes.inputRoot}}
                                    fullWidth
                                    disabled={isUploadInProgress || isUploadDone}
                                    name={"assignmentType"}
                                    onChange={handleAssignmentTypeChange(index)}
                                    renderValue={v => renderAssignmentValue(v, translate)}
                            >
                                {assignmentTypes.map((assignment) => (
                                        <MenuItem key={assignment.businessId} value={assignment}>
                                            <ListItemText primary={renderAssignmentValue(assignment, translate)}/>
                                        </MenuItem>
                                ))}
                            </Select>
                        </Flex>
                )}
            </Flex>
    );
};

export class UploadDialog extends Component {
    state = {
        selectedDocumentTypes: [],
        programs: [],
    };

    handleFileNameChange = (index) => (event) => {
        this.props.handleFilesToUploadChange(
                update(this.props.filesToUpload, {
                    [index]: {
                        fileNameWithoutExtension: {$set: event.target.value},
                        fileName: {$set: event.target.value + "." + this.props.filesToUpload[index].fileExtension},
                    },
                })
        );
    };

    handleDocumentTypeChange = (index) => (event) => {
        this.props.handleFilesToUploadChange(
                update(this.props.filesToUpload, {
                    [index]: {
                        documentType: {$set: event.target.value},
                    },
                })
        );
    };

    handleAssignmentTypeChange = (index) => (event) => {
        this.props.handleFilesToUploadChange(
                update(this.props.filesToUpload, {
                    [index]: {
                        documentAssignment: {$set: event.target.value},
                    },
                })
        );
    };

    render() {
        const {
            classes,
            t: translate,
            open,
            closeUploadDialog,
            uploadFiles,
            documentTypes,
            filesToUpload,
            assignmentType,
            assignmentTypes,
        } = this.props;
        const {handleFileNameChange, handleDocumentTypeChange, handleAssignmentTypeChange} = this;

        const isAnyFileUploadingOrUnprocessable = !!filesToUpload.find(
                (file) => !file.documentType || file.isUploadInProgress
        );
        const isUploadInProgress = !!filesToUpload.find((file) => file.isUploadInProgress);

        const isMobile = utils.checkMobileBrowser();

        const handleUploadOnclick = (event) => {
            event.stopPropagation();
            event.preventDefault();
            uploadFiles();
        };

        return (
                <Dialog open={open} onClose={closeUploadDialog} maxWidth={"lg"} fullWidth>
                    <Flex item container column padding={20}>
                        <Flex
                                id={"header"}
                                item
                                container
                                style={{marginBottom: 5, borderBottom: `1px solid ${grey}`}}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                        >
                            <Flex item container alignItems={"center"}>
                                <Typography
                                        {...{
                                            ...(isMobile ? {style: {fontWeight: "bold"}} : {variant: "h5"}),
                                        }}
                                >
                                    {translate("case.document-upload-title")}
                                </Typography>
                            </Flex>

                            <Flex item container justifyContent={"flex-end"}>
                                <IconButton onClick={closeUploadDialog}>
                                    <CloseIcon/>
                                </IconButton>
                                {isUploadInProgress && (
                                        <Flex item grow={0} container padding={3}>
                                            <CircularProgress/>
                                        </Flex>
                                )}
                                {!isUploadInProgress && (
                                        <IconButton data-testid="submit-upload-btn" onClick={handleUploadOnclick}
                                                    disabled={isAnyFileUploadingOrUnprocessable}>
                                            <CheckIcon/>
                                        </IconButton>
                                )}
                            </Flex>
                        </Flex>
                        {filesToUpload.find((file) => !file.documentType) && (
                                <Flex item container column padding={5}>
                                    <Flex item container style={{marginBottom: 15}}>
                                        <Typography {...{...(isMobile && {variant: "h6"})}}>
                                            {isMobile
                                                    ? translate("case.document-upload-not-ready")
                                                    : translate("case.document-upload-not-ready-explanation")}
                                        </Typography>
                                    </Flex>
                                    <DocumentHeader translate={translate}
                                                    withAssignmentColumn={Boolean(assignmentType)}/>
                                    {(filesToUpload || []).map((file, index) =>
                                            !file.documentType ? (
                                                    <DocumentRow
                                                            key={index}
                                                            {...{
                                                                classes,
                                                                translate,
                                                                index,
                                                                documentTypes,
                                                                handleFileNameChange,
                                                                handleDocumentTypeChange,
                                                                handleAssignmentTypeChange,
                                                                assignmentTypes,
                                                                assignmentType,
                                                                ...file,
                                                            }}
                                                    />
                                            ) : (
                                                    <Fragment key={index}/>
                                            )
                                    )}
                                </Flex>
                        )}
                        <Flex item container column padding={5}>
                            <Flex item container>
                                <Typography variant={"h6"}>{translate("case.document-upload-ready")}</Typography>
                            </Flex>
                            <DocumentHeader translate={translate} withAssignmentColumn={Boolean(assignmentType)}/>
                            {!filesToUpload.some(x => x.documentType) && (
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                        <div style={{flex: 1, marginRight: 10}}>-</div>
                                        {Boolean(assignmentType) && (<div style={{flex: 1, marginRight: 10}}>-</div>)}
                                        <div style={{flex: 1}}>-</div>
                                    </div>
                            )}
                            {(filesToUpload || []).map((file, index) =>
                                    file.documentType ? (
                                            <DocumentRow
                                                    key={index}
                                                    {...{
                                                        classes,
                                                        translate,
                                                        index,
                                                        documentTypes,
                                                        handleFileNameChange,
                                                        handleDocumentTypeChange,
                                                        handleAssignmentTypeChange,
                                                        assignmentTypes,
                                                        assignmentType,
                                                        ...file,
                                                    }}
                                            />
                                    ) : (
                                            <Fragment key={index}/>
                                    )
                            )}
                        </Flex>
                    </Flex>
                </Dialog>
        );
    }
}

export default withStyles(styles)(withTranslation()(UploadDialog));
