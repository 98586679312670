import { fields } from "./filter-configs/fields.conf";
import { flow } from "lodash/fp";

const filterInNodesWithFieldConfig = (nodes) => {
    return nodes.filter((node) => fields[node.properties.field]);
};

const enrichNodesWithFieldConfigAndIndex = (nodes) => {
    return nodes.map((node, index) => {
        const fieldConfig = fields[node.properties.field];
        return { node, index, config: fieldConfig };
    });
};

const categorizeNodes = (nodes) => {
    return nodes.reduce((categorizedNoes, node) => {
        const { config } = node;
        return !categorizedNoes[config.category]
            ? { ...categorizedNoes, [config.category]: [node] }
            : {
                  ...categorizedNoes,
                  [config.category]: [...categorizedNoes[config.category], node],
              };
    }, {});
};

export const stateToCategorizedComponentData = flow(filterInNodesWithFieldConfig, enrichNodesWithFieldConfigAndIndex, categorizeNodes);
