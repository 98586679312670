import { FormControl, InputLabel, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { MenuItem, TextField } from "@material-ui/core";

const charOperatorToSymbol = {
    eq: "=",
    gt: ">",
    lt: "<",
    gte: "≥",
    lte: "≤",
};

const transformValueToValueWithLabel = (item) => {
    if (typeof item === "object") {
        return { value: item.value, label: item.label };
    }
    return { value: item, label: item };
};

const generateMenuItems = (values, t) => {
    return [""]
        .concat(values.filter((v) => v !== ""))
        .map(transformValueToValueWithLabel)
        .map((value, idx) => (
            <MenuItem key={idx} value={value.value}>
                {t(value.value === "" ? "global.please-select" : value.label)
                    ?.replace(/&lt;/g, "<")
                    .replace(/&gt;/g, ">")}
            </MenuItem>
        ));
};

export const NumberOrSelectRenderer = ({ config, rule, idx, handleUpdateRule }) => {
    const { t } = useTranslation();
    return (
        <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", left: "4px", top: "-16px", whiteSpace: "nowrap", fontSize: "12px" }}>
                {t("anamnesis.cardio.haveCardiacEjectionFractionSubSection")}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div key={idx} style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>
                    <FormControl size="small">
                        <Select

                            style={{
                                marginTop: 0,
                            }}
                            value={rule.properties.numOperator}
                            displayEmpty
                            onChange={(e) => {
                                handleUpdateRule(idx, {
                                    ...rule.properties,
                                    numOperator: e.target.value,
                                });
                            }}
                        >
                            {(config.operators).map((operator, operatorKey) => (
                                <MenuItem key={operatorKey} value={operator}>
                                        {charOperatorToSymbol[operator]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{ gap: "10px", display: "flex" }}>
                        <TextField
                            size="small"
                            style={{ 'width': '80px' }}
                            InputLabelProps={{
                                shrink: true,

                                style: {
                                    position: "absolute",
                                    top: "-20px",
                                },
                            }}
                            InputProps={{
                                style: {
                                    marginTop: 0,
                                },
                            }}
                            type="number"
                            label={
                                config.compact
                                    ? config.operators?.length > 0
                                        ? " "
                                        : config.label || rule.properties.field
                                    : null
                            }
                            value={rule.properties.value.numValue.value}
                            onChange={(e) =>
                                handleUpdateRule(idx, {
                                    ...rule.properties,
                                    value: {
                                        ...rule.properties.value,
                                        numValue: {
                                            field: rule.properties.value.numValue.field,
                                            value: e.target.value === "" ? "" : Number(e.target.value),
                                        },
                                    },
                                })
                            }
                        />
                    </div>
                </div>
                <div style={{marginLeft: '6px'}}>

                    { config.numberLabel}
                </div>
                <div style={{ marginLeft: "8px", marginRight: "8px" }}>
                    { t('global.or').toUpperCase() }
                    </div>
                <FormControl size="small">
                    {config.compact && <InputLabel shrink>{rule.properties.field}</InputLabel>}
                    <Select
                        value={rule.properties.value.selectValue.value}
                        displayEmpty
                        onChange={(e) => {
                            handleUpdateRule(idx, {
                                ...rule.properties,
                                value: {
                                    ...rule.properties.value,
                                    selectValue: {
                                        field: rule.properties.value.selectValue.field,
                                        value: e.target.value,
                                    },
                                },
                            });
                        }}
                    >
                        {generateMenuItems(config.selectValues, t)}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};
