import HMOApi, { Laboratory, MyDefaultLabUnits } from "store/api/HMOApi";

const BASE_URL = "/laboratory";

export const LaboratoryApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        fetchLaboratoryByPatient: build.query({
            query: ({ patientId }) => ({ url: `${BASE_URL}/patient/${patientId}` }),
            providesTags: [Laboratory],
        }),

        saveLaboratory: build.mutation({
            query: (data) => ({
                url: BASE_URL,
                method: "POST",
                data,
            }),
            invalidatesTags: [Laboratory, MyDefaultLabUnits],
        }),

        updateLaboratoryValue: build.mutation({
            query: ({ payload, id }) => ({
                url: `${BASE_URL}/${id}`,
                method: "PUT",
                data: payload,
            }),
            invalidatesTags: [Laboratory, MyDefaultLabUnits],
        }),

        deleteLaboratory: build.mutation({
            query: (id) => ({
                url: `${BASE_URL}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [Laboratory],
        }),
        myDefaultLabUnits: build.query({
            query: () => ({
                url: `/default-lab-units/me`,
            }),
            providesTags: [MyDefaultLabUnits],
        }),
        setMyDefaultLabUnits: build.mutation({
            query: ({payload}) => ({
            url: `/default-lab-units/me`,
            method: "POST",
            data: payload,
            }),
            invalidatesTags: [MyDefaultLabUnits],
        })
    }),
});

export const {
    useFetchLaboratoryByPatientQuery,
    useSaveLaboratoryMutation,
    useUpdateLaboratoryValueMutation,
    useDeleteLaboratoryMutation,
    useMyDefaultLabUnitsQuery,
    useSetMyDefaultLabUnitsMutation,
} = LaboratoryApi;
