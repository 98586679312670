import React, {Component} from 'react';
import {Dialog, Typography, MenuItem, ListItemText} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {withTranslation} from 'react-i18next';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import DatePicker from 'components/calendar/DatePicker';
import MultiInput from 'components/input/MultiInput';
import {Autocomplete} from '@material-ui/lab';
import HmoFilledButton from 'components/button/HmoFilledButton';
import HmoBorderedButton from 'components/button/HmoBorderedButton';
import utils from 'utils/Utils';
import {PATIENT_PAGE} from 'routes/routes';
import {withRouter} from 'utils/Route.utils';
import {connect} from 'react-redux';
import connector from './Patient.connect';
import {CARE_PROVIDER} from 'domain/EntityAuthorizationType.model';
import {PATIENT, DOCTOR, ASSISTANT} from 'domain/User.model';
import _ from 'lodash';
import MultiInputEmailAddressEditor from "scenes/user-manager/access-manager/MultiInputEmailAddressEditor";
import {PATIENT_EDITS_OWN_EMAIL} from "domain/GrantType";

const DateOfBirthTextField = withTranslation()(({t, tReady, ...other}) =>
        <DefaultTextField label={t("case.date-of-birth")} {...other}/>)

export class NewPatientDialog extends Component {

    state = {
        givenName: '',
        familyName: '',
        bioSex: '',
        dateOfBirth: '',
        phoneNumbers: [''],
        emailAddresses: [''],
        careProvider: '',
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    setChange = change => {
        this.setState(change);
    }

    handleDateChange = dateString => {
        this.setState({
            dateOfBirth: dateString
        });
    }

    addPatient = () => {
        this.props.createUserDetails(
                {
                    userDetailsData: {
                        businessId: utils.uuid(),
                        givenName: this.state.givenName,
                        familyName: this.state.familyName,
                        bioSex: this.state.bioSex,
                        dateOfBirth: this.state.dateOfBirth,
                        phoneNumbers: this.state.phoneNumbers,
                        emailAddresses: this.state.emailAddresses
                    },
                    invitedRoleType: PATIENT,
                    entityType: CARE_PROVIDER,
                    entityId: this.isDoctorOrClinicAssistant()
                        ? this.getSelectedCareProviderIdForDoctorOrClinicAssistant()
                        : this.state.careProvider.id
                }).then(response => {
            this.props.navigate(PATIENT_PAGE.pathWithId(response.payload.data.id));
        });
    }

    getSelectedCareProviderIdForDoctorOrClinicAssistant = () => {
        return this.state.careProvider
            ? this.state.careProvider.id
            : this.getCareProviders()[0]?.id
    };

    selectCareProvider = (event, careProvider) => {
        this.setState({careProvider});
    };

    isDoctorOrClinicAssistant = () =>
        this.props.user?.roles.includes(DOCTOR) || this.props.user?.roles.includes(ASSISTANT)

    getCareProviders = () => {
        return this.isDoctorOrClinicAssistant()
                ? this.props.careProviders.filter(cp => this.props.user?.careProviderIds.includes(cp.id))
                : this.props.careProviders
    }

    render() {
        const {t: translate, configuration, cancel} = this.props;
        const {handleChange, addPatient, handleDateChange, setChange, selectCareProvider, isDoctorOrClinicAssistant} = this;
        const {
            givenName, familyName, bioSex, dateOfBirth, phoneNumbers, careProvider, emailAddresses
        } = this.state;
        const disabled = !givenName
                || !familyName
                || !bioSex
                || !dateOfBirth
                || (isDoctorOrClinicAssistant() && this.getCareProviders().length > 1) ? !careProvider : false;
        const allowedCareProviders = this.getCareProviders();
        return (
                <Dialog open={true}>
                    <Flex item container column padding={15}>
                        <Flex item container justifyContent={'space-between'} alignItems={'center'}
                              style={{marginRight: 7}}>
                            <Typography variant={"h5"}>{translate("global.new-patient")}</Typography>
                            <AccountCircleOutlined/>
                        </Flex>
                        <DefaultTextField value={givenName || ''}
                                          label={translate("case.first-name")}
                                          name={'givenName'}
                                          onChange={handleChange}/>

                        <DefaultTextField label={translate("case.last-name")}
                                          value={familyName || ''}
                                          name={'familyName'}
                                          onChange={handleChange}/>

                        <Flex item container>
                            <DefaultTextField label={translate("case.sex")}
                                              select
                                              data-testid="bio-sex-selector"
                                              fullWidth
                                              SelectProps={{
                                                  value: bioSex || '',
                                                  onChange: handleChange,
                                                  name: 'bioSex'
                                              }}>
                                {configuration.bioSexes && configuration.bioSexes.map(bs => (
                                        <MenuItem key={bs} value={bs}>
                                            <ListItemText primary={translate(`global.${bs}`)}/>
                                        </MenuItem>
                                ))}
                            </DefaultTextField>
                            <DatePicker {...{
                                value: dateOfBirth || null,
                                name: 'dateOfBirth',
                                TextFieldComponent: DateOfBirthTextField,
                                onChange: handleDateChange,
                                style: {marginRight: 'unset', flex: '1 1 auto', width: '100%'},
                                            returnLocalDateStringOnChange: true
                            }}/>
                        </Flex>

                        <Flex item container column>
                            <MultiInput {...{
                                inputValues: phoneNumbers,
                                translate,
                                name: 'phoneNumbers',
                                updateInputValues: setChange
                            }}/>

                            <MultiInputEmailAddressEditor {...{
                                scenarioOverride: PATIENT_EDITS_OWN_EMAIL,
                                emailAddresses: emailAddresses,
                                invitedRoleType: PATIENT,
                                person: {},
                                updateInputValues: setChange,
                            }}/>
                        </Flex>
                        <Flex item container>
                            {
                                allowedCareProviders && !_.isEmpty(allowedCareProviders) &&
                                <Autocomplete
                                        data-testid="care-provider-selector"
                                        options={allowedCareProviders || []}
                                        onChange={selectCareProvider}
                                        name={'careProvider'}
                                        value={ (allowedCareProviders.length > 1 ? careProvider : allowedCareProviders[0] ) || null}
                                        style={{width: '100%', marginRight: 7}}
                                        getOptionSelected={(option, value) => {
                                            return option.id === value.id
                                        }}
                                        disabled={allowedCareProviders.length < 2}
                                        getOptionLabel={clinic => clinic.name}
                                        renderOption={clinic => <span data-parent-testid="care-provider-selector">{clinic.name}</span>}
                                        renderInput={params => (<DefaultTextField
                                                {...params}
                                                fullWidth
                                                label={translate("global.care-provider")}
                                                placeholder={translate('global.please-select')}/>)}
                                />
                            }
                        </Flex>
                        <Flex item container style={{marginTop: 10, marginRight: 7}}>
                            <HmoBorderedButton onClick={cancel} size={'small'} style={{width: '50%', marginRight: 10}}>
                                {translate("global.cancel")}
                            </HmoBorderedButton>
                            <HmoFilledButton data-testid="add-patient-btn" onClick={addPatient} disabled={disabled} style={{width: '50%'}}>
                                {translate("global.add-patient")}
                            </HmoFilledButton>
                        </Flex>
                    </Flex>
                </Dialog>
        );
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(
        withTranslation()(
                withRouter(NewPatientDialog)
        )
);
