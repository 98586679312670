import React, { Component, useState } from "react";
import { Tabs, Tab, Button, withStyles, Typography, MenuItem } from "@material-ui/core";
import Flex from "components/grid/Flex";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import { withTranslation } from "react-i18next";
import Contact from "./Contact";
import _ from "lodash";
import NewContactDialog from "./NewContactDialog";
import { CARE_PROVIDER } from "domain/EntityAuthorizationType.model";
import { Autocomplete } from "@material-ui/lab";
import utils from "utils/Utils";
import QuillEditor from "components/quill-editor/QuillEditor";
import { makeStyles } from "@material-ui/core/styles";
import EmailTemplateManager from './EmailTemplateManager';
import {useSelector} from "react-redux";

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        height: "500px",
    },
    tab: {
        fontSize: 14,
        textTransform: "capitalize",
    },
    tabRoot: {
        fontColor: "#E2E1E4",
        "& .MuiTabs-scroller": {},
        "& .MuiTabs-flexContainer": {
            maxHeight: "20px",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#05343F",
            borderWidth: "1px",
        },
    },
    row: {
        padding: theme.spacing(0),
    },
    wysiwygContainer: {
        "& .ql-tooltip": {
            zIndex: 999999,
        },
    },
});

const useStyles = makeStyles(styles);

const CareProviderDetails = (props) => {
    const {
        translate,
        contacts,
        selectedCareProvider,
        handleSelectedCareProviderChange,
        saveSelectedCareProvider,
        cancel,
        selectedCareProviderHasBeenChanged
    } = props;

    const classes = useStyles();
    const careProviderRegistries = useSelector(state => state.caseReducer.configuration.registries) || [];
    const selectRegistry = event => {
        handleSelectedCareProviderChange({
            target: { name: "registryId", value: event.target.value?.id },
        });
    };
    const selectedRegistry = careProviderRegistries.find(item => item.id === selectedCareProvider.registryId);


    const [senderEmail, setSenderEmail] = useState(
        selectedCareProvider.senderEmailForCorrespondence || "support@hmo.de"
    );
    const contactsWithEmails = contacts.filter((c) => c.emailAddresses.length > 0);
    const selectedContact = contacts.find((c) => c.businessId === selectedCareProvider?.defaultContactPersonBusinessId);

    const selectContact = (event, contact) => {
        handleSelectedCareProviderChange({
            target: { name: "defaultContactPersonBusinessId", value: contact.businessId },
        });
    };

    const changeEmailSignature = (value) => {
        handleSelectedCareProviderChange({ target: { name: "emailSignature", value } });
    };

    return (
        <Flex item container column style={{ paddingRight: 10 }}>
            <Flex item container wrap>
                <Flex item container fullWidth>
                    <DefaultTextField
                        fullWidth
                        value={selectedCareProvider?.name || ""}
                        name={"name"}
                        label={translate("global.name")}
                        onChange={handleSelectedCareProviderChange}
                    />
                    <DefaultTextField
                        fullWidth
                        value={selectedCareProvider?.street || ""}
                        name={"street"}
                        label={translate("case.street")}
                        onChange={handleSelectedCareProviderChange}
                    />
                    <DefaultTextField
                        fullWidth
                        value={selectedCareProvider?.zipCode || ""}
                        name={"zipCode"}
                        label={translate("case.zip")}
                        onChange={handleSelectedCareProviderChange}
                    />
                </Flex>
                <Flex item container fullWidth>
                    <DefaultTextField
                        fullWidth
                        value={selectedCareProvider?.city || ""}
                        name={"city"}
                        label={translate("case.city")}
                        onChange={handleSelectedCareProviderChange}
                    />
                    <DefaultTextField
                        fullWidth
                        value={selectedCareProvider?.state || ""}
                        name={"state"}
                        label={translate("case.state")}
                        onChange={handleSelectedCareProviderChange}
                    />
                    <DefaultTextField
                        fullWidth
                        value={selectedCareProvider?.country || ""}
                        name={"country"}
                        label={translate("case.country")}
                        onChange={handleSelectedCareProviderChange}
                    />
                </Flex>
                <DefaultTextField
                        value={selectedCareProvider?.website || ""}
                        name={"website"}
                        fullWidth
                        label={translate("case.website")}
                        onChange={handleSelectedCareProviderChange}
                />
                {contacts && !_.isEmpty(contacts) && (
                    <Autocomplete
                        options={contactsWithEmails || []}
                        onChange={selectContact}
                        name={"selectedContact"}
                        value={selectedContact || null}
                        style={{ width: 250 }}
                        getOptionSelected={(option, value) => {
                            return option.id === value.id;
                        }}
                        getOptionLabel={(c) => utils.formattedUserName(c)}
                        renderOption={(c) => utils.formattedUserName(c)}
                        renderInput={(params) => (
                            <DefaultTextField
                                {...params}
                                fullWidth
                                error={!selectedContact}
                                helperText={!selectedContact ? translate('care-provider.missing-default-contact'): ''}
                                label={translate("global.default-contact")}
                                placeholder={translate("global.please-select")}
                            />
                        )}
                    />
                )}
                <Flex item container fullWidth>
                    <DefaultTextField
                            style={{ width: 250, marginBottom: 20 }}
                            name={"registry"}
                            onChange={selectRegistry}
                            value={selectedRegistry || ""}
                            label={translate('email-manager.registry')}
                            select
                    >
                        {
                            careProviderRegistries.map(item =>
                                    <MenuItem value={item} key={item.id}>
                                        {item.name}
                                    </MenuItem>)
                        }
                    </DefaultTextField>
                </Flex>


            </Flex>
            <div style={{ marginBottom: "10px", width: "50%" }}>
                <Typography variant="subtitle2">{translate('global.sender-email-address-for-correspondence-with-patients')}</Typography>
                <DefaultTextField
                    data-testid="sender-email-selector"
                    name={"senderEmailForCorrespondence"}
                    onChange={handleSelectedCareProviderChange}
                    value={selectedCareProvider?.senderEmailForCorrespondence || ""}
                    error={!selectedCareProvider?.senderEmailForCorrespondence}
                    helperText={!selectedCareProvider ? translate('care-provider.missing-sender-email'): ''}
                    style={{ width: 250, marginBottom: 10 }}
                    label={translate('global.default-sender')}
                    select
                >
                    <MenuItem data-parent-testid="sender-email-selector" value={"support@hmo.de"}>support@hmo.de</MenuItem>
                    <MenuItem data-parent-testid="sender-email-selector" value={"info@krebszweitmeinung.de"}>info@krebszweitmeinung.de</MenuItem>
                    <MenuItem data-parent-testid="sender-email-selector" value={"support@chip-register.de"}>support@chip-register.de</MenuItem>
                </DefaultTextField>
                <Typography variant="subtitle2" style={{ marginBottom: "10px" }}>
                    {translate("global.email-signature-for-correspondence")}
                </Typography>
                <div className={classes.wysiwygContainer}>
                    <QuillEditor
                        containerStyle={{}}
                        toolbar={[
                            [{ 'font': [] }, { 'size': [] }],
                            [ 'bold', 'italic', 'underline', 'strike' ],
                            [{ 'color': [] }, { 'background': [] }],
                            [{ 'script': 'super' }, { 'script': 'sub' }],
                            [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
                            [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
                            [ 'direction', { 'align': [] }],
                            [ 'clean' ]
                        ]}
                        handleTextChange={changeEmailSignature}
                        text={selectedCareProvider?.emailSignature}
                    />
                </div>
            </div>
            <Flex item container column></Flex>
            <Flex item container justifyContent={"flex-start"}>
                <Button
                    disabled={!selectedCareProviderHasBeenChanged}
                    color={"secondary"}
                    variant={"contained"}
                    onClick={cancel}
                    style={{ width: 150, marginRight: 10 }}
                >
                    {translate("global.cancel")}
                </Button>
                <Button
                    disabled={!selectedCareProviderHasBeenChanged}
                    color={"primary"}
                    variant={"contained"}
                    onClick={saveSelectedCareProvider}
                    style={{ width: 150, marginLeft: 10, marginRight: 7 }}
                >
                    {translate("global.save")}
                </Button>
            </Flex>
        </Flex>
    );
};

function CareProviderContacts(props) {
    const {
        translate,
        contacts,
        mainContacts,
        careProviderId,
        refreshContacts,
        excludedUserIds,
        existingInvitesForEntity,
    } = props;
    const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
    const toggleContactDialog = () => setIsContactDialogOpen(!isContactDialogOpen);
    return (
        <Flex id={"clinic-contacts"} item={"0 1 auto"} container column padding={10}>
            <Flex item grow={0} container justifyContent={"flex-end"} style={{ marginBottom: 10 }}>
                <Button
                    onClick={toggleContactDialog}
                    style={{
                        backgroundColor: "#245B67",
                        textTransform: "capitalize",
                        padding: "0px 30px",
                        color: "white",
                        maxHeight: 37,
                        height: 37,
                        borderRadius: 10,
                    }}
                    size={"small"}
                >
                    + {translate("global.add-contact")}
                </Button>
            </Flex>

            {_.isEmpty(mainContacts) && (
                <Flex item container center>
                    <Typography style={{ fontSize: 20, fontWeight: "bold", opacity: 0.5 }}>
                        {translate("global.no-contacts-yet")}
                    </Typography>
                </Flex>
            )}

            {(mainContacts || []).length > 0 && (
                <Flex item container style={{ flexWrap: "wrap" }}>
                    {mainContacts.map((contact, index) => (
                        <Contact
                            key={`${contact.businessId} - ${index}`}
                            {...{
                                userDetailsId: contact.id,
                                contacts,
                                careProviderId,
                                refresh: refreshContacts,
                                excludedUserIds,
                                existingInvitesForEntity,
                            }}
                        />
                    ))}
                </Flex>
            )}

            {isContactDialogOpen && (
                <NewContactDialog
                    {...{
                        careProviderId,
                        refreshContacts: () => {
                            refreshContacts();
                            toggleContactDialog();
                        },
                        cancel: toggleContactDialog,
                    }}
                />
            )}
        </Flex>
    );
}

export class CareProviderEdit extends Component {
    state = {
        activityTab: "DETAILS",
        contacts: [],
        alreadyInvitedEmails: [],
    };

    componentDidMount() {
        this.refreshContacts();
        this.props
            .getAlreadyInvitedEmails({
                entityId: this.props.selectedCareProvider.id,
                entityType: CARE_PROVIDER,
            })
            .then((response) => this.setState({ alreadyInvitedEmails: response.payload.data }));
    }

    refreshContacts = () => {
        this.props.fetchCareProviderContacts(this.props.selectedCareProvider.id).then((response) => {
            this.setState({ contacts: response.payload.data });
        });
    };

    handleActivityTabChange = (event, value) => {
        this.setState({ activityTab: value });
    };

    render() {
        const {
            t: translate,
            classes,
            selectedCareProvider,
            handleSelectedCareProviderChange,
            saveSelectedCareProvider,
            cancel,
            programs,
            selectedCareProviderHasBeenChanged
        } = this.props;
        const { activityTab, contacts, alreadyInvitedEmails } = this.state;
        const { refreshContacts, handleActivityTabChange } = this;
        const mainContacts = contacts.filter((contact) => _.isEmpty(contact.subordinateToBusinessId));
        const excludedUserIds = contacts.filter((c) => c.userId).map((c) => c.userId);
        return (
            <Flex item container column style={{ marginLeft: 80 }}>
                <Tabs
                    value={activityTab}
                    onChange={handleActivityTabChange}
                    style={{ left: 90 }}
                    className={classes.tabRoot}
                >
                    <Tab value={"DETAILS"} className={classes.tab} label={translate("global.details")} />
                    <Tab value={"CONTACT"} className={classes.tab} label={translate("global.contact-person")} />
                    <Tab value={"EMAIL_MANAGER"} className={classes.tab} label={translate("global.email-manager")} />
                </Tabs>
                {activityTab === "DETAILS" && (
                    <CareProviderDetails
                        {...{
                            translate,
                            selectedCareProvider,
                            handleSelectedCareProviderChange,
                            cancel,
                            saveSelectedCareProvider,
                            programs,
                            contacts,
                            selectedCareProviderHasBeenChanged
                        }}
                    />
                )}
                {activityTab === "CONTACT" && (
                    <CareProviderContacts
                        {...{
                            translate,
                            contacts,
                            mainContacts,
                            careProviderId: selectedCareProvider.id,
                            refreshContacts,
                            excludedUserIds,
                            existingInvitesForEntity: alreadyInvitedEmails,
                        }}
                    />
                )}
                {activityTab === "EMAIL_MANAGER" && (
                    <EmailTemplateManager
                        {...{
                            emailTemplateEntityType: 'CARE_PROVIDER',
                            entityId: selectedCareProvider.id,
                        }}
                    />
                )}
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(CareProviderEdit));
