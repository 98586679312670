import { Set } from "immutable";
import { Category } from "../categories";
import { WidgetType } from "../widgetTypes";

export const switchWithSelectAndTagsFieldConstructor = ({
    pathToSwitchQuestion,
    pathToSelectValue,
    pathToValue, category = Category.OTHER, label, nestedComponent = false,
    selectOptions,
    tagOptions,
 }) => ({
    [pathToValue]: {
        widgetType: WidgetType.SWITCH_WITH_SELECT_AND_TAGS,
        category: nestedComponent ? undefined : category,
        defaultRuleProperties: {
            value: {
                type: "switchWithSelectAndTags",
                tagsValue: Set(),
                switchValue: {
                    field: pathToSwitchQuestion,
                    value: true,
                },
                selectValue: {
                    field: pathToSelectValue,
                    value: "",
                },
            },
        },
        widgetConfig: {
            label: label,
            tagsValues: [
                { value: "rattlingNoises", label: "anamnesis.clinical.rattlingNoisesButton" },
                { value: "neckVeinCongestion", label: "anamnesis.clinical.neckVeinCongestionButton" },
                { value: "periphereOdeme", label: "anamnesis.clinical.periphereOdemeButton" },
                { value: "ruhedyspnose", label: "anamnesis.clinical.ruhedyspnoseButton" },
            ],
            selectValues: selectOptions,
        },
    },
});
