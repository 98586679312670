import { Switch, FormControl, InputLabel, Select, MenuItem, Box } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import _ from "lodash";
import moment from "moment";

export const SwitchWIthYearSelectRenderer = ({ config, rule, idx, handleUpdateRule }) => {
    const { t } = useTranslation();
    return (
        <Box key={idx} display="flex" flexDirection="row" alignItems="center" style={{ gap: "10px" }}>
            <Box display="flex" alignItems="center">
                <Trans i18nKey="global.no" />
                <Switch
                    checked={rule.properties.value.switchValue.value}
                    size="small"
                    onChange={(e) =>
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                switchValue: {
                                    field: rule.properties.value.switchValue.field,
                                    value: e.target.checked,
                                },
                            },
                        })
                    }
                    color="primary"
                />
                <Trans i18nKey="global.yes" />
            </Box>
            <FormControl size="small">
                <Select
                    disabled={!rule.properties.value.switchValue.value}
                    value={rule.properties.yearOperator}
                    displayEmpty
                    onChange={(e) => {
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            yearOperator: e.target.value,
                        });
                    }}
                >
                    <MenuItem value={""}>{t("global.please-select")}</MenuItem>
                    <MenuItem value={"lt"}>{t("global.before")}</MenuItem>
                    {config.withEqualYear && <MenuItem value={"eq"}>Equal</MenuItem>}
                    <MenuItem value={"gt"}>{t("global.after")}</MenuItem>
                </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    style={{ width: "120px" }}
                    disabled={!rule.properties.value.switchValue.value}
                    size="small"
                    value={rule.properties.value.yearValue.value ? moment(rule.properties.value.yearValue.value) : null}
                    views={["year"]}
                    list="autocompleteOff"
                    autoComplete="new-password"
                    onChange={(e) => {
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                yearValue: {
                                    field: rule.properties.value.yearValue.field,
                                    value: e.startOf("year").toISOString(),
                                },
                            },
                        });
                    }}
                    disableFuture
                />
            </MuiPickersUtilsProvider>
        </Box>
    );
};
