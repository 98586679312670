import { Category } from "../categories";
import { WidgetType } from "../widgetTypes";

export const percentRangeFieldConstructor = ({ pathToValue, category = Category.OTHER, label, fieldWidth = 48, nestedComponent = false, }) => ({
  [pathToValue]: {
        widgetType: WidgetType.NUMBER_RANGE,
        category: nestedComponent ? undefined : category,
        defaultRuleProperties: {
            value: {
                type: "range",
                from: 20,
                to: 80,
            },
        },
        widgetConfig: {
            width: fieldWidth,
            label: label,
        },
    },
});
