export const Category = {
    "GENERAL": "general",
    "OTHER": "other",
    "MEDICATIONS": "medications",
    "CHIP": "chip",
    "CARDIO_RISK_FACTORS": "cardioRiskFactors",
    "CARDIOVASCULAR_DISEASES": "cardiovascularDiseases",
    "QUALITY_OF_LIFE": "qualityOfLife",
    "RISK_SCORE": "riskScore",
    "COMORBIDITIES": "comorbidities",
    "LABORATORY": "cohort.lab-values",
    "HEMATOLOGY": "hematology",
    "ARTHROSE": "arthrose",
 }

 export const categoryLabels = {
    [Category.GENERAL]: "anamnesis.general.title",
    [Category.OTHER]: "global.other",
    [Category.CHIP]: "anamnesis.chip.title",
    [Category.CARDIO_RISK_FACTORS]: "anamnesis.cardiovascular.title",
    [Category.QUALITY_OF_LIFE]: "anamnesis.quality.shortTitle",
    [Category.RISK_SCORE]: "my-risk.risk-score",
    [Category.COMORBIDITIES]: "anamnesis.comorbidity.title",
    [Category.HEMATOLOGY]: "anamnesis.hematology.title",
    [Category.MEDICATIONS]: "medication.title",
    [Category.ARTHROSE]: "anamnesis.arthrose.title",
    [Category.CARDIOVASCULAR_DISEASES]: "anamnesis.cardio.cardiovasclar-disase"
 }
