import React, {useEffect, useState} from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import _ from "lodash";
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import utils from "utils/Utils";

const CustomYearPicker = ({form, value, name, component, as, type, label, format = "YYYY", views = ['year'],
                              placeholder, ...props}) => {
    const [selectedDate, setSelectedDate] = useState('');
    const {t} = useTranslation();
    const handleDateChange = (date) => {
        const truncatedDate = date?.isValid()
                ? date?.format(utils.API_LOCAL_DATE_FORMAT)
                : date;
        setSelectedDate(truncatedDate);

        if (props.onChange != null) {
            props.onChange({target: {value: truncatedDate}});
        } else {
            throw new Error('onChange is not defined in CustomYearPicker');
        }
    };

    useEffect(() => {
        if (value != null && !_.isEmpty(value) && value !== 'UNDEFINED') {
            setSelectedDate(value);
        }
    }, [value]);

    return <MuiPickersUtilsProvider key={`mui-picker-${name}`} utils={MomentUtils}>
        <KeyboardDatePicker
                {...props}
                format={format}
                list="autocompleteOff"
                autoComplete="new-password"
                views={views}
                {...(label === '' ? {label: ''} : label ? {label} : {label: t('global.in-which-year')})}
                placeholder={placeholder ? placeholder : label === '' ? t('global.in-which-year') : t('global.year')}
                value={selectedDate ? moment(selectedDate) : null}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                disableFuture
        />
    </MuiPickersUtilsProvider>;
};

export default CustomYearPicker;
