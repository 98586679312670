import React, {Component, Fragment} from 'react';
import {Route, Routes, Navigate} from 'react-router';
import {
    CASES,
    CASE_EDIT,
    CLINICS,
    CLINICS_EDIT,
    LOGOUT,
    RESET_PASSWORD,
    MANAGED_CARE,
    MANAGED_CARE_PROGRAM_TEMPLATES,
    PATIENT_PAGE,
    REGISTRATION,
    REGISTRATION_WITH_INVITE,
    WELCOME,
    ROLE_SELECTOR,
    CARE_PROVIDERS,
    PATIENT_PAGE_PROGRAM,
    PATIENT_PAGE_ANAMNESE,
    PATIENT_PAGE_MEDICATION_PLAN,
    PATIENT_PAGE_LABORATORY,
    PATIENT_PAGE_MY_RISK,
    COHORT_FILTERS,
    FILTERS,
    COHORTS,
    FILTER_NEW,
    FILTER_EDIT,
    COHORT_EDIT,
    PATIENT_PAGE_HISTORY_LOG,
    SYSTEM_ADMIN_PATIENT_LIST
} from 'routes/routes';
import Login from 'scenes/login/Login';
import Registration from 'scenes/registration/Registration';
import Welcome from 'scenes/registration/Welcome';
import Logout from 'components/logout/Logout';
import CaseList from 'scenes/case/list/CaseList';
import CaseEdit from 'scenes/case/edit/CaseEdit';
import ClinicsList from 'scenes/clinics/list/ClinicsList';
import ClinicsEdit from 'scenes/clinics/edit/ClinicsEdit';
import PatientList from 'scenes/managed-care/PatientList';
import CareProviders from 'scenes/care-provider/CareProviders';
import ProgramManager from 'scenes/managed-care/program-template/ProgramTemplateManager';
import MedicationPlanList from "scenes/patient/medication-plan/list/MedicationPlanList";
import AnamnesisForm from "scenes/patient/anamnesis/component/AnamnesisForm";
import LaboratoryForm from "scenes/patient/laboratory/component/LaboratoryForm";
import MyRisk from "scenes/patient/my-risk/MyRisk";
import PatientPrograms from "scenes/patient/PatientPrograms";
import ProgramView from "scenes/patient/program-view/ProgramView";
import {connect} from 'react-redux';
import connector from './Main.connect';
import _ from 'lodash';
import {CASE_MANAGER, CONTACT_PERSON, PATIENT, DOCTOR, ASSISTANT, CLINIC} from "domain/User.model";
import SessionTimeout from 'components/session-timeout/SessionTimeout';
import IdleTimer from './IdleTimer';
import RoleSelector from 'scenes/user-manager/RoleSelector';
import PatientPage from "./patient/PatientPage";
import HistoryLog from "./patient/history-log/HistoryLog";
import LoadingWithSidebar from "components/sidebar/LoadingWithSidebar";
import { BlockedScreen } from './BlockedScreen';
import { isPatient } from 'domain/User.model';
import { MainWalkthrough } from './walkthrough/mainWalkthrough/mainWalkthrough';
import CohortFilters from "scenes/cohort-filters/CohortFilters";
import CohortFilterNew from "./cohort-filters/CohortFilterNew";
import CohortFilterEdit from "./cohort-filters/CohortFilterEdit";
import Cohorts from "./cohort-filters/Cohorts";
import CohortEdit from "./cohort-filters/CohortEdit";
import CohortFiltersMainPage from "./cohort-filters/CohortFiltersMainPage";
import SystemAdminPatientList from "./patient/SystemAdminPatientList";

function isRoleSelectionRequired(roles = []) {
    return roles?.length > 1 && (roles?.includes(CONTACT_PERSON) || roles?.includes(PATIENT));
}

/**
 *
 * @param {{patientId: number, invitedRoleType: string}[]} accesses
 * @returns {number | null} null in case if there are more than one patient assigned to contact person
 */
const contactPersonPatientId = (accesses) => {
    const patientAccessIds = accesses
        .filter((x) => x.invitedRoleType === "CONTACT_PERSON")
        .reduce((acc, c) => (acc.includes(c.patientId) ? acc : [...acc, c.patientId]), []);
    return patientAccessIds.length === 1 ? patientAccessIds[0] : null;
};

const NavigateBasedOnRoles = ({user}) => {
    const {roles, userDetailsId} = user;
    if (roles?.length === 0) return <Navigate to={WELCOME.path}/>;
    if (isRoleSelectionRequired(roles)) return <Navigate to={ROLE_SELECTOR.path}/>;
    if (roles?.includes(CASE_MANAGER) || roles?.includes(CLINIC)) return <Navigate to={CASES.path}/>;
    if (roles?.includes(PATIENT)) return <Navigate to={PATIENT_PAGE.pathWithId(userDetailsId)}/>;
    if (roles?.includes("CONTACT_PERSON")) {
        let contactPersonPatient = contactPersonPatientId(user.accesses);
        if (contactPersonPatient) {
            return <Navigate to={PATIENT_PAGE.pathWithId(contactPersonPatient)}/>;
        }
    }
    return <Navigate to={MANAGED_CARE.path}/>;
};

export class Main extends Component {

    idleTimer = null;
    intervalId = null;

    componentDidMount() {
        this?.props?.loadAccessToken();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.authenticated && this.props.authenticated) {
            if (this.is(CASE_MANAGER) || this.is(CLINIC)) {
                this.onActive();
            } else if(this.props.user.businessId && this.props.user?.roles?.length > 0 && !this.is(CLINIC)) {
                this.props.getWalkthroughStatuses();
            }
            if (this.props.user?.roles?.length > 0) {
                this.props.setupCaseConfigurations();
            }
            if (!window.location.hostname.includes('localhost')) {
                this.props.initializeWebsocket();
            }
        }
    }

    is = role => {
        return (_.get(this.props, 'user.roles') || []).includes(role);
    };

    onIdle = () => {
        clearInterval(this.intervalId);
        this.intervalId = null;
    }

    onActive = () => {
        if (!this.intervalId) {
            this.props.heartBeat();
            this.intervalId = setInterval(() => {
                this.props.heartBeat();
            }, 1000 * 60 * 3);
        }
    }

    render() {
        const {is} = this;
        const {authenticated, accessToken, isConfigurationLoaded, user, appScreenIsBlocked, appIntroWalkthrough} = this.props;
        return (
                <Fragment>
                    <Routes>
                        {
                            authenticated && isRoleSelectionRequired(user?.roles) &&
                            <Route path={ROLE_SELECTOR.path} element={<RoleSelector/>}/>
                        }
                        {
                            authenticated && !isRoleSelectionRequired(user?.roles) && (is(CASE_MANAGER) || is(CLINIC)) &&
                            <Route path={CASES.path}>
                                <Route index element={<CaseList/>}/>
                                <Route path={CASE_EDIT.path} element={isConfigurationLoaded ? <CaseEdit/> : <></>}/>
                            </Route>
                        }
                        {
                            authenticated && !isRoleSelectionRequired(user?.roles) && is(CASE_MANAGER) &&
                            <Route path={CLINICS.path}>
                                <Route index element={<ClinicsList/>}/>
                                <Route path={CLINICS_EDIT.path} element={<ClinicsEdit/>}/>
                            </Route>
                        }
                        {
                            authenticated && !isRoleSelectionRequired(user?.roles) && is(CASE_MANAGER) &&
                            <Route path={CARE_PROVIDERS.path}>
                                <Route index element={<CareProviders/>}/>
                            </Route>
                        }
                        {
                            authenticated && (is(CASE_MANAGER) || is(DOCTOR) || is(ASSISTANT) || is(CONTACT_PERSON)) &&
                            <Route path={MANAGED_CARE.path}>
                                <Route index element={<PatientList/>}/>
                                {
                                    (is(CASE_MANAGER) || is(DOCTOR) || is(ASSISTANT)) &&
                                            <Route path={MANAGED_CARE_PROGRAM_TEMPLATES.path}
                                                   element={<ProgramManager/>}/>
                                }
                            </Route>
                        }
                        {
                                is(CASE_MANAGER) &&
                                <Route path={SYSTEM_ADMIN_PATIENT_LIST.path}
                                       element={<SystemAdminPatientList/>}/>
                        }
                        {
                            authenticated && !isRoleSelectionRequired(user?.roles)
                            && (is(CASE_MANAGER) || is(DOCTOR) || is(ASSISTANT) || is(CONTACT_PERSON) || is(PATIENT)) &&
                            <Route path={PATIENT_PAGE.path} element={isConfigurationLoaded ? <PatientPage/> : <></>}>
                                <Route index element={<PatientPrograms/>}/>
                                <Route path={PATIENT_PAGE_PROGRAM.path} element={<ProgramView/>}/>
                                <Route path={PATIENT_PAGE_MEDICATION_PLAN.path} element={<MedicationPlanList/>}/>
                                <Route path={PATIENT_PAGE_ANAMNESE.path} element={<AnamnesisForm/>}/>
                                <Route path={PATIENT_PAGE_LABORATORY.path} element={<LaboratoryForm/>}/>
                                <Route path={PATIENT_PAGE_MY_RISK.path} element={<MyRisk/>}/>
                                {
                                    !is(CONTACT_PERSON) && !is(PATIENT) &&
                                        <Route path={PATIENT_PAGE_HISTORY_LOG.path} element={<HistoryLog/>}/>
                                }
                            </Route>
                        }
                        {
                            authenticated && !isRoleSelectionRequired(user?.roles)
                            && (is(CASE_MANAGER) || is(DOCTOR) || is(ASSISTANT)) &&
                            <Route path={COHORT_FILTERS.path} element={isConfigurationLoaded ? <CohortFiltersMainPage/> : <></>}>
                                <Route index element={<CohortFiltersMainPage/>}/>
                                <Route path={FILTERS.path} element={<CohortFilters/>}/>
                                <Route path={COHORTS.path} element={<Cohorts/>}/>
                                <Route path={FILTER_NEW.path} element={<CohortFilterNew/>}/>
                                <Route path={FILTER_EDIT.path} element={<CohortFilterEdit/>}/>
                                <Route path={COHORT_EDIT.path} element={<CohortEdit/>}/>
                            </Route>
                        }

                        <Route path={REGISTRATION.path}>
                            <Route index element={<Registration/>}/>
                            <Route path={REGISTRATION_WITH_INVITE.path} element={<Registration/>}/>
                        </Route>
                        <Route path={RESET_PASSWORD.path} element={<Login/>}/>
                        <Route path={LOGOUT.path} element={<Logout/>}/>
                        <Route path={WELCOME.path} element={<Welcome/>}/>

                        <Route path={'*'} element={
                            !accessToken && !authenticated
                                    ? <Login/>
                                    : accessToken && !authenticated
                                        ? <LoadingWithSidebar/>
                                        : <NavigateBasedOnRoles user={user}/>
                        }/>
                    </Routes>
                    <SessionTimeout/>
                    {/* new component */}
                    { authenticated && !appIntroWalkthrough && !isRoleSelectionRequired(user?.roles) && isPatient(user) && <MainWalkthrough /> }
                    {
                        authenticated && is(CASE_MANAGER) &&
                        <IdleTimer
                                ref={ref => {
                                    this.idleTimer = ref
                                }}
                                timeout={1000 * 60 * 5}
                                onActive={this.onActive}
                                onIdle={this.onIdle}
                        />
                    }
                    <BlockedScreen isShown={appScreenIsBlocked} />
                </Fragment>
        )
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(Main);
