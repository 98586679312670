import { useReducer } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Flex from "components/grid/Flex";
import { Typography, Box, IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Accordion from "components/grid/Accordion";
import InvitedContactPerson from "scenes/case/edit/component/patient/InvitedContactPerson";
import CareProviderWithAccess from "scenes/case/edit/component/patient/CareProviderWithAccess";
import _ from "lodash";
import { DeleteContactModal } from "./DeleteContactModal";
import { isManager, isPatient } from "domain/User.model";
import { getAccessesOffered } from "scenes/program/Program.action";
import {
    useDeleteAccessAssignmentsByIdsMutation,
    useDeleteAccessAssignmentByIdMutation,
} from "scenes/patient/ProgramAccessApi";
import { DeleteContactInvitationModal } from "./DeleteContactInvitationModal";
import { DeleteCareProviderAccessModal } from "./DeleteCareProviderAccessModal";
import { useRejectAccessToEntityForInvitedEmailMutation } from "scenes/patient/PatientApi";
import {FULL_PATIENT} from "../../domain/EntityAuthorizationType.model";

export const Action = Object.freeze({
    OpenDeleteModal: "OPEN_DELETE_MODAL",
    OpenDeleteClinicModal: "OPEN_DELETE_CLINIC_MODAL",
    CloseDeleteModal: "CLOSE_DELETE_MODAL",
    LoadAccesses: "LOAD_ACCESSES",
    OpenDeleteInvitationModal: "OPEN_DELETE_INVITATION_MODAL",
    CloseDeleteInvitationModal: "CLOSE_DELETE_INVITATION_MODAL",
});

const Contacts = ({
    contacts,
    alreadyExitingInvites,
    careProvidersForPatient,
    user,
    patientId,
    patientFullName,
    selectedProgramId,
}) => {
    const alreadyExitingInvitedEmailsWithFullAccess = alreadyExitingInvites
            ?.filter(invite => invite.entityType === FULL_PATIENT)
            ?.map(invite => invite.invitedEmail);
    const filteredAlreadyExitingInvites = alreadyExitingInvites?.filter(invite => invite.entityType === FULL_PATIENT
            || (!alreadyExitingInvitedEmailsWithFullAccess.includes(invite.invitedEmail)));
    const [deleteAccessAssignments] = useDeleteAccessAssignmentsByIdsMutation();
    const [deleteCareProviderAccessAssignment] = useDeleteAccessAssignmentByIdMutation();
    const [rejectAccessToEntityForInvitedEmail] = useRejectAccessToEntityForInvitedEmailMutation();
    const initialState = {
        openedDeleteModalAccesses: [],
        openedDeleteModalAccessesLoading: false,
        deleteModalOpen: false,
        deleteAccessContact: null,
        deleteContactInvitationModelOpen: false,
        deleteContactInvitationEmail: null,
        deleteClinicModalOpen: false,
        careProviderAccess: null,
    };
    const reducer = (state, action) => {
        switch (action.type) {
            case Action.OpenDeleteModal:
                return {
                    ...state,
                    deleteModalOpen: true,
                    deleteAccessContact: action.payload,
                    openedDeleteModalAccessesLoading: true,
                };
            case Action.OpenDeleteClinicModal:
                return {
                    ...state,
                    careProviderAccess: action.payload,
                    deleteClinicModalOpen: true,
                };
            case Action.CloseDeleteClinicModal:
                return {
                    ...state,
                    careProviderAccess: null,
                    deleteClinicModalOpen: false,
                };
            case Action.CloseDeleteModal:
                return {
                    ...state,
                    deleteModalOpen: false,
                    deleteAccessContact: null,
                    openedDeleteModalAccesses: [],
                    openedDeleteModalAccessesLoading: false,
                };
            case Action.LoadAccesses:
                return {
                    ...state,
                    openedDeleteModalAccesses: action.payload,
                    openedDeleteModalAccessesLoading: false,
                };

            case Action.OpenDeleteInvitationModal: {
                return {
                    ...state,
                    deleteContactInvitationModelOpen: true,
                    deleteContactInvitation: action.payload,
                };
            }
            case Action.CloseDeleteInvitationModal: {
                return {
                    ...state,
                    deleteContactInvitationModelOpen: false,
                    deleteContactInvitation: null,
                };
            }
            default:
                return state;
        }
    };
    const [state, dispatchAction] = useReducer(reducer, initialState);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const ableToRemoveAccesses = isManager(user) || isPatient(user);

    const loadAccesses = (accessForUser) => {
        dispatch(getAccessesOffered(patientId, accessForUser, "CONTACT_PERSON")).then((response) =>
            dispatchAction({ type: Action.LoadAccesses, payload: response.payload.data }),
        );
    };

    const deleteContactPersonAccess = (ids) => {
        deleteAccessAssignments({ patientId, ids }).finally(() => {
            dispatchAction({ type: Action.CloseDeleteModal });
        });
    };

    return (
        <>
            <Flex item container column>
                {_.isEmpty(contacts) && _.isEmpty(alreadyExitingInvites) && (
                    <Typography style={{ opacity: 0.54 }}>{t("global.no-access-yet")}</Typography>
                )}
                {_.sortBy(contacts || [], "givenName").map((contact) => (
                    <Accordion
                        key={contact.id}
                        accordionOpen={false}
                        containerStyle={{
                            boxShadow: "unset",
                            border: "1px solid #859EC2",
                            borderRadius: 10,
                        }}
                        style={{
                            borderRadius: 10,
                            backgroundColor: "white",
                        }}
                        titleComponent={
                            <Flex item style={{ alignItems: "center" }} container justifyContent={"space-between"}>
                                <Box>
                                    <Typography style={{ fontWeight: "bold" }}>
                                        {!(contact.givenName || contact.familyName || contact.userEmailAddress)
                                            ? t("clinic.contact-person")
                                            : ""}
                                        {!(contact.givenName || contact.familyName) && contact.userEmailAddress}
                                        {contact.givenName + " "}
                                        {contact.familyName}
                                    </Typography>
                                    {contact?.accessLevel ? t(`access-level.${contact.accessLevel}`) : ""}
                                </Box>
                                {ableToRemoveAccesses && (
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            dispatchAction({
                                                type: Action.OpenDeleteModal,
                                                payload: contact,
                                            });
                                        }}
                                    >
                                        <DeleteOutlineIcon style={{ fontSize: "21px" }} />
                                    </IconButton>
                                )}
                            </Flex>
                        }
                    >
                        <div style={{ marginLeft: 34 }}>
                            <div>E-Mail: {contact.userEmailAddress}</div>
                            {contact?.comment && (
                                <div>
                                    {" "}
                                    {t("global.comment")} : {contact.comment}
                                </div>
                            )}
                        </div>
                    </Accordion>
                ))}

                {_.sortBy(filteredAlreadyExitingInvites || [], "invitedName").map((invite) => (
                    <InvitedContactPerson
                        key={invite?.inviteToken}
                        onDelete={() => {
                            dispatchAction({ type: Action.OpenDeleteInvitationModal, payload: invite });
                        }}
                        invite={invite}
                    />
                ))}

                {_.sortBy(careProvidersForPatient || [], "name").map((careProviderAccess) => (
                    <CareProviderWithAccess
                        onDelete={() => {
                            dispatchAction({ type: Action.OpenDeleteClinicModal, payload: careProviderAccess });
                        }}
                        key={careProviderAccess?.id}
                        {...{ careProviderAccess }}
                    />
                ))}
            </Flex>
            {state.deleteModalOpen && (
                <DeleteContactModal
                    open={state.deleteModalOpen}
                    onClose={() => {
                        dispatchAction({ type: Action.CloseDeleteModal });
                    }}
                    contact={state.deleteAccessContact}
                    loadAccesses={loadAccesses}
                    accesses={state.openedDeleteModalAccesses.filter((access) => access.selected)}
                    accessesLoading={state.openedDeleteModalAccessesLoading}
                    onSubmit={deleteContactPersonAccess}
                />
            )}

            {state.deleteContactInvitationModelOpen && (
                <DeleteContactInvitationModal
                    patientFullName={patientFullName}
                    email={state.deleteContactInvitation?.invitedEmail}
                    open={state.deleteContactInvitationModelOpen}
                    onClose={() => {
                        dispatchAction({ type: Action.CloseDeleteInvitationModal });
                    }}
                    onDelete={() => {
                        rejectAccessToEntityForInvitedEmail({
                            email: state.deleteContactInvitation?.invitedEmail,
                            entityType: state.deleteContactInvitation?.entityType,
                            entityId: state.deleteContactInvitation?.entityId,
                        }).finally(() => {
                            dispatchAction({ type: Action.CloseDeleteInvitationModal });
                        });
                    }}
                />
            )}
            <DeleteCareProviderAccessModal
                open={state.deleteClinicModalOpen}
                patientId={patientId}
                careProviderAccess={state.careProviderAccess}
                onClose={() => {
                    dispatchAction({ type: Action.CloseDeleteClinicModal });
                }}
                onDelete={(id) => {
                    deleteCareProviderAccessAssignment({ patientId, ids: [id] });
                    dispatchAction({ type: Action.CloseDeleteClinicModal });
                }}
            />
        </>
    );
};

export default Contacts;
