import { SwitchWithSelectRenderer } from ".";
import { TagsRenderer } from "./TagsRenderer";

export const SwitchWithSelectAndTagsRenderer = ({ config, rule, idx, handleUpdateRule }) => {
    return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <SwitchWithSelectRenderer config={config} rule={rule} idx={idx} handleUpdateRule={handleUpdateRule} />
        <div style={{flexShrink: 0}}>
            <TagsRenderer disabled={!rule.properties.value.switchValue.value} config={config} rule={rule} idx={idx} handleUpdateRule={handleUpdateRule} />
        </div>
    </div>
};
