import Slider from "@material-ui/core/Slider";

export const PercentRange = ({ rule, idx, handleUpdateRule, ...other }) => {
    console.log(other);
    const value = [rule.properties.value.from, rule.properties.value.to];
    const marks = Array.from({length: 101}, (_, i) => i)
    .filter((i) => i % 20 === 0)
    .map((i) => ({
        value: i,
        label: i.toString() + '%',
    }));
    return (
        <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px'}}>
            <div style={{ width: "300px" }}>
                <Slider
                    value={value}
                    valueLabelDisplay="auto"
                    marks={marks}
                    onChange={(e, newValue) => {
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                type: rule.properties.value.type,
                                from: newValue[0],
                                to: newValue[1],
                            },
                        });
                    }}
                ></Slider>
            </div>
        </div>
    );
};
