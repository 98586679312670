import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const TagsRenderer = ({ config, rule, idx, handleUpdateRule, disabled }) => {
    const valueKey = rule.properties.value.type === "tags" ? "value" : "tagsValue";
    const tagValuesKey = config.values ? "values" : "tagsValues";
    const activeTags = rule.properties.value[valueKey];
    const { t } = useTranslation();

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {config[tagValuesKey].map((tag) => (
                <Button
                    disabled={disabled}
                    key={tag.value}
                    onClick={() => {
                        handleUpdateRule(idx, {
                            ...rule.properties,
                            value: {
                                ...rule.properties.value,
                                [valueKey]: activeTags.has(tag.value) ? activeTags.delete(tag.value) : activeTags.add(tag.value),
                            }
                        });
                    }}
                    style={{ marginLeft: "4px" }}
                    disableElevation
                    disableRipple
                    size="small"
                    color={activeTags.has(tag.value) ? "primary" : "default"}
                    variant="contained"
                >
                    {t(tag.label)}
                </Button>
            ))}
        </div>
    );
};
