import { Category } from "../categories";
import { WidgetType } from "../widgetTypes";

export const switchWithYearSelectConstructor = ({
    pathToSwitchQuestion,
    pathToYearValue,
    category = Category.OTHER,
    label,
    withEqualYear = false
}) => ({
    [pathToSwitchQuestion]: {
        widgetType: WidgetType.SWITCH_WITH_YEAR_SELECTOR,
        category: category,
        defaultRuleProperties: {
            value: {
                type: "switchWithYearSelect",
                switchValue: {
                    field: pathToSwitchQuestion,
                    value: true,
                },
                yearValue: {
                    field: pathToYearValue,
                    value: "",
                },
            },
            yearOperator: "",
        },
        widgetConfig: {
            label: label,
            withEqualYear
        },
    },
});

