import { Category } from "../categories";
import { WidgetType } from "../widgetTypes";
export const numberOrSelectFieldConstructor = ({ pathToNumValue, pathToSelectValue, pathToValue, category = Category.OTHER, label, fieldWidth = 48, withComparisonOperators = true, nestedComponent = false, selectOptions }) => ({
  [pathToValue]: {
        widgetType: WidgetType.NUMBER_OR_SELECT,
        category: nestedComponent ? undefined : category,
        defaultRuleProperties: {
            value: {
                type: "numberOrSelect",
                numValue: {
                    field: pathToNumValue,
                    value: "",
                },
                selectValue: {
                    field: pathToSelectValue,
                    value: "",
                },
                switchValue: {
                    field: pathToValue,
                    value: pathToValue ? true : undefined,
                },
            },
            numOperator: "eq"
        },
        widgetConfig: {
            width: fieldWidth,
            label: label,
            selectValues: selectOptions,
            numberLabel: '%',
            operators: ["eq", "gt", "lt", "gte", "lte"],
        },
    },
});


