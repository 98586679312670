import Flex from "components/grid/Flex";
import { Button, Checkbox, Typography } from "@material-ui/core";
import { useFindPatientDuplicatesQuery, useMergeSelectedPatientsMutation } from "./PatientApi";
import { FixedSizeList } from "react-window";
import React, { useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import _ from "lodash";
import { PATIENT_PAGE } from "../../routes/routes";
import { useEnqueueError, useEnqueueSuccess } from "../../components/alert/SnackbarHooks";
import { Link } from "react-router-dom";

const SystemPatientRow = ({ style, patientRow, selectedPatients, setSelectedPatients }) => {
    const selectForMerge = (nameHash, patientIdToKeep) => () => {
        setSelectedPatients({ ...selectedPatients, [nameHash]: patientIdToKeep });
    };
    return (
        <div style={style}>
            <Flex item container column style={{ border: "1px solid grey" }}>
                <Typography>{patientRow.nameHash}</Typography>
                {patientRow.patients.map((patient, index) => (
                    <Flex
                        key={patient.id}
                        item
                        container
                        alignItems={"center"}
                        style={{ borderBottom: index === 0 ? "1px solid lightgrey" : 0 }}
                    >
                        <span
                            style={{
                                padding: 2,
                                flex: "0 0 15%",
                            }}
                        >
                            {patient.id + " - " + patient.givenName + " " + patient.familyName}
                        </span>
                        <span
                            style={{
                                padding: 2,
                                flex: "0 0 20%",
                                fontWeight: patient.emailAddresses ? "bold" : "",
                            }}
                        >
                            {patient.emailAddresses}
                        </span>
                        <span
                            style={{
                                padding: 2,
                                flex: "0 0 25%",
                            }}
                        >{`${patient.zipCode || ""} ${patient.city || ""} ${patient.street || ""}`}</span>
                        <span style={{ padding: 2, flex: "0 0 10%" }}>
                            {`${patient.numberOfMedication || 0}/${patient.numberOfAnamneses || 0}/${patient.numberOfLaboratory || 0}/`}
                            <span
                                style={{
                                    fontWeight: patient.numberOfDocuments ? "bold" : "",
                                    fontSize: patient.numberOfDocuments ? 18 : 14,
                                }}
                            >
                                {patient.numberOfDocuments || 0}
                            </span>
                        </span>
                        <span
                            style={{
                                padding: 2,
                                flex: "0 0 10%",
                            }}
                        >
                            {patient.thirdPartyId ? "Imported, " : "In app, "}
                            <span style={{ fontWeight: patient.userEmailAddress ? "bold" : "" }}>
                                {patient.userEmailAddress ? "Has User" : "No User"}
                            </span>
                        </span>

                        <Checkbox
                            checked={selectedPatients[patientRow.nameHash] === patient.id}
                            onChange={selectForMerge(patientRow.nameHash, patient.id)}
                            disabled={patientRow.patients.length > 2}
                            style={{ padding: 2 }}
                        />
                        {selectedPatients[patientRow.nameHash] &&
                            selectedPatients[patientRow.nameHash] === patient.id &&
                            "Will be kept"}
                        {selectedPatients[patientRow.nameHash] &&
                            selectedPatients[patientRow.nameHash] !== patient.id &&
                            "Marked to delete"}
                        <Flex item container style={{ justifyContent: "flex-end" }}>
                            <Button
                                style={{ padding: 2, alignSelf: "flex-end" }}
                                component={Link}
                                to={PATIENT_PAGE.pathWithId(patient.id)}
                                target={"_blank"}
                            >
                                Open
                            </Button>
                        </Flex>
                    </Flex>
                ))}
            </Flex>
        </div>
    );
};

const Row = ({ data, index, style }) => {
    const { selectedPatients, setSelectedPatients, rows } = data;
    return (
        <SystemPatientRow
            key={index}
            {...{
                style,
                selectedPatients,
                setSelectedPatients,
                patientRow: rows[index],
            }}
        />
    );
};

function PatientListWindow(props) {
    const { width, height, ref } = useResizeDetector();
    const { patientsGroupedByNameHash, selectedPatients, setSelectedPatients } = props;
    const rows = patientsGroupedByNameHash.filter((p) => p.patients.length === 2);
    return (
        <div
            ref={ref}
            style={{
                height: "95vh",
                marginLeft: 10,
                marginRight: 10,
            }}
        >
            <FixedSizeList
                height={height || 300}
                itemCount={rows.length}
                itemSize={84}
                width={width || 500}
                itemData={{
                    rows,
                    selectedPatients,
                    setSelectedPatients,
                }}
            >
                {Row}
            </FixedSizeList>
        </div>
    );
}

const SystemAdminPatientList = ({ route }) => {
    const enqueueError = useEnqueueError();
    const enqueueSuccess = useEnqueueSuccess();
    const { data: patientsGroupedByNameHash, refetch } = useFindPatientDuplicatesQuery(null, {
        refetchOnMountOrArgChange: true,
    });
    const [mergeSelectedPatients] = useMergeSelectedPatientsMutation();
    const [selectedPatients, setSelectedPatients] = useState({});
    const mergeAll = () => {
        mergeSelectedPatients(
            Object.keys(selectedPatients).reduce((acc, key) => {
                return {
                    ...acc,
                    [selectedPatients[key]]: patientsGroupedByNameHash
                        .find((p) => p.nameHash === key)
                        .patients.find((p) => p.id !== selectedPatients[key]).id,
                };
            }, {}),
        )
            .unwrap()
            .then(() => {
                enqueueSuccess();
                refetch();
            })
            .catch(() => {
                enqueueError();
            })
            .finally(() => {
                setSelectedPatients({});
            });
    };
    return (
        <Flex item container column>
            <Button variant={"contained"} onClick={mergeAll} style={{ margin: "10px 10px 0px 10px" }}>
                Merge selected
            </Button>
            <Typography style={{ margin: "0px 10px 0px 10px" }}>
                Meds, anamnese and labs will be cloned. Nothing happens to docs, you have to handle them manually. Open
                opens in new window. Patients marked for deletion can be recovered normally, but you have to delete the
                cloned data from the kept patients manually. Where the duplication is not obvious, or both entity is
                heavily worked on already (number of meds,labs, anamnese, docs is hight for both) DO NOT merge. Handle
                them manually
            </Typography>
            <Flex item container style={{ margin: 10, backgroundColor: "lightgrey" }}>
                <span style={{ flex: "0 0 15%" }}>Name</span>
                <span style={{ flex: "0 0 20%" }}>Email Addresses</span>
                <span style={{ flex: "0 0 25%" }}>Address</span>
                <span style={{ flex: "0 0 15%" }}># of meds/ anamneses /labs /docs</span>
            </Flex>
            {!_.isEmpty(patientsGroupedByNameHash) && (
                <PatientListWindow
                    {...{
                        patientsGroupedByNameHash,
                        selectedPatients,
                        setSelectedPatients,
                    }}
                />
            )}
        </Flex>
    );
};

export default SystemAdminPatientList;
