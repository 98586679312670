import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetchLaboratoryByPatientQuery, useMyDefaultLabUnitsQuery } from "scenes/patient/laboratory/LaboratoryApi";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ChartData, ChartDataset, ChartOptions, FormInputData, substanceBaseUnits } from "../config";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import update from "immutability-helper";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart } from "chart.js";
import _ from "lodash";
import LaboratoryFormDialog from "./LaboratoryFormDialog";
import { substanceAndUnitToUnitLabel } from "../config";
import { ButtonGroup, Button } from "@material-ui/core";
import { useSetMyDefaultLabUnitsMutation } from "scenes/patient/laboratory/LaboratoryApi";
import {
    CRP,
    GFR,
    HDL_CHOLESTEROL,
    HEMOGLOBIN,
    KREATININ,
    LDL_CHOLESTEROL,
    LEUKOZYTEN,
    MCV,
    RDW,
    THROMBOZYTEN,
    TOTAL_CHOLESTEROL,
} from "../../../../domain/Laboratory.model";

Chart.register(zoomPlugin);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    chart: {
        maxHeight: 320,
        background: "#F8F8FA",
        border: "1px solid #E2E1E4",
        borderRadius: "15px",
        paddingBottom: "80px",
    },

    chartContainer: {
        position: "relative",
    },

    noChartValuesMessage: {
        color: theme.palette.text.disabled,
        fontSize: "1.1em",
        position: "absolute",
        top: "46%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },

    chartTitle: {
        marginLeft: "15px",
    },
}));

const LaboratoryDashboard = ({ readonly }) => {
    const { data: userDefaultUnits } = useMyDefaultLabUnitsQuery();
    const [setMyDefaultLabUnitsMutation] = useSetMyDefaultLabUnitsMutation();
    const classes = useStyles();
    const { patientId } = useParams();
    const { t } = useTranslation();
    const lang = useCallback((name) => t(`laboratory.timeline.${name}`), [t]);
    const [chartDataSet, setCharDataSet] = useState(
        _.range(1, ChartData({ lang }).length + 1).map(() => ({ data: [] })),
    );

    const dataSetToMinMaxDates = (data) => {
        if (!Array.isArray(data) || data.length === 0) {
            return { minDate: null, maxDate: null };
        }

        const parseDate = (record) => new Date(record.recordDate);
        const dates = data.map(parseDate);

        const minDate = new Date(Math.min(...dates));
        const maxDate = new Date(Math.max(...dates));

        return { minDate, maxDate };
    };

    const getNoDataForChartLabel = useCallback(
        (valueName) => t(`laboratory.timeline.no-values-yet`, { valueName }),
        [t],
    );
    const { data: laboratoryData, isSuccess: laboratoryDataLoadedSuccessfully } = useFetchLaboratoryByPatientQuery(
        { patientId },
        { skip: !patientId },
    );

    useEffect(() => {
        if (laboratoryDataLoadedSuccessfully && laboratoryData.length > 0) {
            const chartDataSetPayload = {
                lang,
                chartData: update(laboratoryData, {
                    $apply: (data) => data.slice().sort((a, b) => new Date(a.recordDate) - new Date(b.recordDate)),
                }),
                userDefaultUnits,
            };
            const chartDataset = ChartDataset(chartDataSetPayload);
            setCharDataSet(chartDataset);
        }
    }, [lang, laboratoryData, laboratoryDataLoadedSuccessfully, userDefaultUnits]);

    const getMinMaxDates = (laboratoryData) => {
        const minMaxDates = laboratoryDataLoadedSuccessfully
            ? dataSetToMinMaxDates(laboratoryData)
            : {
                  minDate: null,
                  maxDate: null,
              };
        const minDate = minMaxDates.minDate
            ? new Date(minMaxDates.minDate.setMonth(minMaxDates.minDate.getMonth() - 1))
            : null;
        const maxDate = minMaxDates.maxDate
            ? new Date(minMaxDates.maxDate.setMonth(minMaxDates.maxDate.getMonth() + 1))
            : null;
        return { minDate, maxDate };
    };

    const calculateMinMax = useCallback(
        (chartOptions, data) => {
            const { minDate, maxDate } = getMinMaxDates(laboratoryData);

            const xScaleUpdate = {
                type: "time",
                suggestedMin: minDate,
                suggestedMax: maxDate,
            };
            const yScaleUpdate =
                data?.data != null && _.isArray(data.data) && data.data.length > 0
                    ? {
                          suggestedMax: Math.ceil(
                              Math.max(...data.data.map((e) => e.y)) + Math.max(...data.data.map((e) => e.y)) * 0.1,
                          ),
                          suggestedMin: Math.max(
                              0,
                              Math.ceil(
                                  Math.min(...data.data.map((e) => e.y)) -
                                      Math.max(Math.min(...data.data.map((e) => e.y)) * 0.1, 2),
                              ),
                          ),
                      }
                    : {};

            const newOptions = update(chartOptions, {
                scales: {
                    x: { $merge: xScaleUpdate },
                    y: { $merge: yScaleUpdate },
                },
            });

            return newOptions;
        },
        [laboratoryData],
    );

    return (
        <Grid style={{ marginBottom: 40 }}>
            <Grid container justifyContent={"flex-end"} style={{ marginBottom: 20 }}>
                {!readonly && <LaboratoryFormDialog userDefaultUnits={userDefaultUnits} />}
            </Grid>
            {ChartData({ lang }).map((type) => (
                <div className={classes.chartContainer} key={type?.title + type?.index}>
                    {chartDataSet[type.index].data.length === 0 ? (
                        <span className={classes.noChartValuesMessage}>{getNoDataForChartLabel(type.title)}</span>
                    ) : null}
                    <Grid key={type.index} item xs={12} style={{ marginBottom: 40 }}>
                        <div className={classes.chart}>
                            <h4 className={classes.chartTitle}>
                                {type.title}

                                <ButtonGroup
                                    style={{ marginLeft: "10px" }}
                                    disableElevation
                                    size="small"
                                    variant="outlined"
                                    color="default"
                                >
                                    {FormInputData.find((e) => e.id === type.name).options.units.map(
                                        ({ value, label }) => {
                                            const selectedUnit =
                                                value ===
                                                (userDefaultUnits?.[type.name + "Unit"] ||
                                                    substanceBaseUnits[type.name]);
                                            return (
                                                <Button
                                                    key={value}
                                                    color={selectedUnit ? "primary" : "default"}
                                                    style={{ fontWeight: selectedUnit ? "bold" : "normal", pointerEvents: selectedUnit ? "none" : "auto" }}
                                                    onClick={() => {
                                                        if(selectedUnit) return;
                                                        if (!userDefaultUnits) {
                                                            const payload = {
                                                                hemoglobinUnit: substanceBaseUnits[HEMOGLOBIN],
                                                                kreatininUnit: substanceBaseUnits[KREATININ],
                                                                totalcholesterolUnit:
                                                                    substanceBaseUnits[TOTAL_CHOLESTEROL],
                                                                ldlcholesterolUnit: substanceBaseUnits[LDL_CHOLESTEROL],
                                                                hdlcholesterolUnit: substanceBaseUnits[HDL_CHOLESTEROL],
                                                                crpUnit: substanceBaseUnits[CRP],
                                                                leukozytenUnit: substanceBaseUnits[LEUKOZYTEN],
                                                                mcvUnit: substanceBaseUnits[MCV],
                                                                rdwUnit: substanceBaseUnits[RDW],
                                                                thrombozytenUnit: substanceBaseUnits[THROMBOZYTEN],
                                                                gfrUnit: substanceBaseUnits[GFR],
                                                                ...{ [type.name + "Unit"]: value },
                                                            };
                                                            setMyDefaultLabUnitsMutation({ payload });
                                                        } else {
                                                            setMyDefaultLabUnitsMutation({
                                                                payload: {
                                                                    ...userDefaultUnits,
                                                                    [type.name + "Unit"]: value,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                >
                                                    {label}
                                                </Button>
                                            );
                                        },
                                    )}
                                </ButtonGroup>
                            </h4>
                            <Line
                                id={`chart-${type.index}`}
                                key={`chart-${type.index}`}
                                data={{ datasets: [chartDataSet[type.index]] }}
                                options={{ ...calculateMinMax(ChartOptions, chartDataSet[type.index]) }}
                            />
                        </div>
                    </Grid>
                </div>
            ))}
        </Grid>
    );
};

export default LaboratoryDashboard;
