import React, { useEffect, useRef, useState } from "react";
import Flex from "components/grid/Flex";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Button, CircularProgress, ClickAwayListener, IconButton, MenuItem, Typography } from "@material-ui/core";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import PatientHeader from "./PatientHeader";
import PatientRow from "./PatientRow";
import { FixedSizeList } from "react-window";
import { useResizeDetector } from "react-resize-detector";
import HmoInputWithAdornment from "components/input/HmoInputWithAdornment";
import { CloseOutlined as CloseIcon, Search, Settings } from "@material-ui/icons";
import utils, { stringGet } from "utils/Utils";
import Tag from "components/tags/Tag";
import update from "immutability-helper";
import * as colors from "components/colors/Colors";
import _ from "lodash";
import Sidebar from "components/sidebar/Sidebar";
import { isCaseManager, isManager } from "domain/User.model";
import { MANAGED_CARE_PROGRAM_TEMPLATES, PATIENT_PAGE } from "routes/routes";
import NewPatientDialog from "scenes/patient/NewPatientDialog";
import { Skeleton } from "@material-ui/lab";
import { useIsContactPerson } from "utils/useIsContactPerson";
import { useContactConfirmationOfPatientConfidentialityQuery, useListPatientsQuery } from "scenes/patient/PatientApi";
import DefaultTextField from "../../components/hmo-textfield/DefaultTextField";
import { useGetIsPermissionGrantedQuery } from "../user-manager/UserDetailAccessApi";
import {
    useAddPatientsToCohortMutation,
    useListCohortsQuery,
    useRemovePatientsFromCohortMutation,
} from "../cohort-filters/CohortApi";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import NewProgramDialog from "../patient/NewProgramDialog";
import PatientRowContextMenu from "./PatientRowContextMenu";
import DownloadIcon from "@mui/icons-material/Download"
import DownloadingIcon from '@mui/icons-material/Downloading';
import { downloadCohortAsFile } from "../cohort-filters/downloadCohortAsFile";
import { useEnqueueError } from "../../components/alert/SnackbarHooks";

const Filters = (props) => {
    const {
        translate,
        toggleNewPatientDialog,
        programNamesFromPatients,
        selectedPrograms,
        toggleProgram,
        positiveSearch,
        resetPositiveSearch,
        setPositiveSearch,
        user,
        inProgress,
    } = props;
    return (
        <>
            <Flex
                item={"0 1 auto"}
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                style={{
                    marginBottom: 10,
                    marginLeft: 70,
                    marginRight: 10,
                }}
            >
                <Flex item container alignItems={"center"}>
                    {inProgress && (
                        <CircularProgress style={{marginRight: 5}}/>
                    )}
                    <HmoInputWithAdornment
                        value={positiveSearch}
                        placeholder={translate("global.search")}
                        onChange={(event) => setPositiveSearch(event?.target?.value)}
                        name={"positiveSearch"}
                        rootStyle={{
                            padding: 0,
                            marginRight: 15,
                            paddingLeft: 10,
                            width: 420,
                            border: "1px solid #859EC2",
                            backgroundColor: "unset",
                        }}
                        startAdornment={<Search />}
                        endAdornment={
                            !_.isEmpty(positiveSearch) && (
                                <IconButton
                                    style={{
                                        padding: 3,
                                        backgroundColor: colors.silver,
                                        fontSize: 13,
                                        marginRight: 3,
                                    }}
                                    onClick={resetPositiveSearch}
                                >
                                    <CloseIcon style={{ color: colors.concrete, fontSize: "inherit" }} />
                                </IconButton>
                            )
                        }
                    />

                    {programNamesFromPatients.map((item, index) => (
                        <Tag
                            {...{
                                key: index,
                                selected: selectedPrograms.includes(item),
                                tag: item,
                                toggleSelection: toggleProgram,
                            }}
                        />
                    ))}
                    {isManager(user) && (
                        <IconButton
                            data-testid="manage-program-templates-btn"
                            component={Link}
                            to={MANAGED_CARE_PROGRAM_TEMPLATES.path}
                        >
                            <Settings />
                        </IconButton>
                    )}
                </Flex>
                {isManager(user) && (
                    <Button
                        data-testid="add-new-patient-btn"
                        onClick={toggleNewPatientDialog}
                        style={{
                            backgroundColor: "#245B67",
                            textTransform: "capitalize",
                            padding: "0px 30px",
                            color: colors.htmlWhite,
                            maxHeight: 37,
                            height: 37,
                            borderRadius: 10,
                        }}
                        size={"small"}
                    >
                        + {translate("global.add-new-patient")}
                    </Button>
                )}
            </Flex>
        </>
    );
};

const Row = ({ data, index, style }) => {
    const {
        selectPatient,
        selectedPrograms,
        toggleProgram,
        refresh,
        checkedPatientIds,
        checkPatient,
        handleOnRightClick,
        rows,
    } = data;
    const isContactPerson = useIsContactPerson();
    // It's important to pass undefined (not null) as the first argument to the query
    // otherwise cached results will not be shared with other calls
    useContactConfirmationOfPatientConfidentialityQuery(undefined, {
        skip: !isContactPerson,
    }); // Don't need the result just want to trigger the query
    return (
        <PatientRow
            key={index}
            {...{
                style,
                displayForContactPerson: isContactPerson,
                patientOverview: rows[index],
                selectPatient,
                selectedPrograms,
                toggleProgram,
                refresh,
                checkedPatientIds,
                checkPatient,
                handleOnRightClick,
            }}
        />
    );
};

function PatientListWindow(props) {
    const { width, height, ref } = useResizeDetector();
    const {
        patients,
        selectPatient,
        selectedPrograms,
        toggleProgram,
        refresh,
        checkedPatientIds,
        checkPatient,
        handleOnRightClick,
    } = props;

    return (
        <div
            ref={ref}
            style={{
                height: "80vh",
                marginLeft: 70,
                marginRight: 10,
            }}
        >
            <FixedSizeList
                height={height || 300}
                itemCount={patients.length}
                itemSize={40}
                width={width || 500}
                itemData={{
                    rows: patients,
                    selectPatient,
                    selectedPrograms,
                    toggleProgram,
                    refresh,
                    checkedPatientIds,
                    checkPatient,
                    handleOnRightClick,
                }}
            >
                {Row}
            </FixedSizeList>
        </div>
    );
}

const PatientList = () => {
    const user = useSelector((state) => state.mainReducer.user);
    const [search, setSearch] = useSearchParams();
    const { state } = useLocation();
    const { cohortIdFromLocation } = state || {};
    const userDetails = useSelector((state) => state.mainReducer.userDetails);
    const { data: cohorts = [] } = useListCohortsQuery();
    const {
        data: patientListObject = {
            patients: [],
            programNamesFromPatients: [],
        },
        refetch,
        isLoading: isPatientQueryLoading,
    } = useListPatientsQuery(null, { refetchOnMountOrArgChange: true });
    const {data: isPermissionGratedForUser} = useGetIsPermissionGrantedQuery({ userDetailsId: userDetails?.id, permissionType: "COHORT_EXPORT" }, {skip: !userDetails?.id});
    const isPermissionGranted = isCaseManager(user) || isPermissionGratedForUser;
    const { patients, programNamesFromPatients } = patientListObject;
    const { t: translate } = useTranslation();
    const navigation = useNavigate();
    const [sortParameter, setSortParameter] = useState(search.get("sort") || "");
    const [reverseSort, setReverseSort] = useState(search.get("reverseSort") === "true" || false);
    const [positiveSearch, setPositiveSearch] = useState("");
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [isNewPatientDialogOpen, setIsNewPatientDialogOpen] = useState(false);
    const [checkedPatientIds, setCheckedPatientIds] = useState([]);
    const [selectedCohortId, setSelectedCohortId] = useState(cohortIdFromLocation || 0);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [allCurrentPatientsAreChecked, setAllCurrentPatientsAreChecked] = useState(false);
    const selectCohort = (cohortId) => {
        setSelectedCohortId(cohortId);
        setCheckedPatientIds([]);
    };
    const onSort = (newSortParameter) => (event) => {
        const newReverseSort = newSortParameter === sortParameter ? !reverseSort : false
        setSearch({ sort: newSortParameter, reverseSort: newReverseSort });
        setReverseSort(newReverseSort);
        setSortParameter(newSortParameter);
    };

    const toggleProgram = (program) => {
        const index = selectedPrograms.findIndex((item) => item === program);
        setSelectedPrograms(
            index === -1
                ? update(selectedPrograms, {
                      $push: [program],
                  })
                : update(selectedPrograms, {
                      $splice: [[index, 1]],
                  }),
        );
    };

    const freeTextFilter = (patient) => {
        // Split the positiveSearch string into an array of lowercase words by space and comma
        const searchWords = positiveSearch.toLowerCase().split(/[\s,]+/);

        const includesAnyWord = (fieldValue) => {
            const lowerCaseFieldValue = fieldValue.toLowerCase();
            return searchWords.some((word) => lowerCaseFieldValue.includes(word));
        };

        return (
            positiveSearch === "" ||
            stringGet(patient, "emailAddresses", "").includes(positiveSearch) ||
            stringGet(patient, "phoneNumbers", "").includes(positiveSearch) ||
            includesAnyWord(stringGet(patient, "givenName", "")) ||
            includesAnyWord(stringGet(patient, "familyName", ""))
        );
    };

    const resetPositiveSearch = () => {
        setPositiveSearch("");
    };

    const programsFilter = (patient) => {
        return _.isEmpty(selectedPrograms)
            ? true
            : selectedPrograms.every((programName) => patient?.programs?.split(",").includes(programName));
    };

    const cohortFilter = (patient) => {
        return !selectedCohortId
            ? true
            : cohorts?.find((cohort) => cohort.id === selectedCohortId)?.patientIds?.includes(patient?.patientId);
    };

    const selectPatient = (patientId) => (event) => {
        if (!x && !y) navigation(PATIENT_PAGE.pathWithId(patientId));
    };

    const toggleNewPatientDialog = () => {
        setIsNewPatientDialogOpen(!isNewPatientDialogOpen);
    };

    const addUnseenSum = (item) => {
        return {
            ...item,
            unseenSum: item.unseenDocuments + item.unseenChats + item.unseenContacts,
        };
    };

    const filteredPatients = patients
        .filter(freeTextFilter)
        .filter(programsFilter)
        .filter(cohortFilter)
        .map(addUnseenSum);

    const orderedPatients = sortParameter
        ? utils.orderByFalsyLast(filteredPatients, [sortParameter], [reverseSort ? "desc" : "asc"])
        : utils.orderByFalsyLast(
              filteredPatients,
              ["unseenSum", "date", "description", "familyName"],
              ["desc", "asc", "asc", "asc"],
          );

    const checkPatient = (id) => {
        if (checkedPatientIds.includes(id)) {
            setCheckedPatientIds(checkedPatientIds.filter((item) => item !== id));
            setAllCurrentPatientsAreChecked(false);
        } else {
            const newCheckedIds = [...checkedPatientIds, id];
            setCheckedPatientIds(newCheckedIds);
            if (newCheckedIds.length === orderedPatients.length) {
                setAllCurrentPatientsAreChecked(true);
            }
        }
    };

    const checkAllPatientToggle = () => {
        if (allCurrentPatientsAreChecked) {
            setCheckedPatientIds([]);
            setAllCurrentPatientsAreChecked(false);
        } else {
            setCheckedPatientIds(orderedPatients.map((item) => item?.patientId));
            setAllCurrentPatientsAreChecked(true);
        }
    };

    const handleOnRightClick = (event) => {
        setX(event.pageX);
        setY(event.pageY);
        event?.preventDefault();
        event?.stopPropagation();
    };

    const resetPosition = (event) => {
        event?.stopPropagation();
        event?.preventDefault();
        setX(0);
        setY(0);
    };

    const [isNewProgramDialogOpen, setIsNewProgramDialogOpen] = useState(false);
    const toggleNewProgramDialog = () => setIsNewProgramDialogOpen(!isNewProgramDialogOpen);
    const [cohortIsDownloading, setCohortIsDownloading] = useState(false);
    const enqueueError = useEnqueueError();

    const downloadCohortHandler = (cohortId) => {
        setCohortIsDownloading(true);
        downloadCohortAsFile(cohortId)
            .catch(() => {
                enqueueError();
            })
            .finally(() => setCohortIsDownloading(false));
    };

    return (
        <>
            <Sidebar />
            <Box style={{ marginLeft: 60 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    style={{
                        color: "gray",
                        fontWeight: 500,
                        borderBottom: "1px solid gray",
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        paddingLeft: "10px",
                    }}
                >
                    {isCaseManager(user) ? (
                        <>
                            {translate("global.informal-salutation")}
                            {user.givenName ? "," : ""} <span style={{ color: "black" }}>{user.givenName}</span>
                        </>
                    ) : (
                        <>
                            {translate("global.formal-salutation")},{" "}
                            {`${translate(
                                userDetails?.salutation ? translate("global." + userDetails?.salutation) : "",
                            )} ${userDetails?.title || ""}`}
                            <span data-testid="formal-salutation" style={{ color: "black" }}>
                                {" "}
                                {user.familyName}
                            </span>
                        </>
                    )}
                </Typography>
                <Flex item container>
                    <Typography
                        component="h2"
                        style={{ color: colors.sideBarColor, paddingLeft: "10px", marginTop: "10px", width: 420 }}
                        variant="h3"
                    >
                        {translate("global.here-are-your-patients")} (n={patients.length})
                    </Typography>
                    {isManager(user) && (
                        <Flex item={"0 0 0"} container alignItems={"center"}>
                            <Typography
                                style={{
                                    marginTop: 10,
                                    marginRight: 7,
                                    marginLeft: 30,
                                }}
                            >
                                {translate("global.cohorts")}
                            </Typography>
                            <DefaultTextField
                                label=""
                                select
                                SelectProps={{
                                    value: cohorts.find((cohort) => cohort.id === selectedCohortId)?.id || 0,
                                    onChange: (event, value) => selectCohort(event.target.value),
                                }}
                                style={{ width: 200 }}
                            >
                                <MenuItem key={"Empty"} value={0}>
                                    {translate("global.all")}
                                </MenuItem>
                                {cohorts.map((cohort) => (
                                    <MenuItem key={cohort.id} value={cohort.id}>
                                        {cohort.name}
                                    </MenuItem>
                                ))}
                            </DefaultTextField>
                          {selectedCohortId && isManager(user) && isPermissionGranted ? <Button disabled={cohortIsDownloading} style={{marginTop: '12px'}} onClick={() => {downloadCohortHandler(selectedCohortId)}} variant="outlined" endIcon={cohortIsDownloading ? <DownloadingIcon/> : <DownloadIcon />}>
                                EXCEL
                            </Button> : null}
                        </Flex>
                    )}
                </Flex>
            </Box>

            <Flex item container column>
                <Filters
                    {...{
                        translate,
                        toggleNewPatientDialog,
                        programNamesFromPatients,
                        selectedPrograms,
                        toggleProgram,
                        positiveSearch,
                        resetPositiveSearch,
                        setPositiveSearch,
                        user,
                        inProgress: isPatientQueryLoading && _.isEmpty(patients),
                    }}
                />
                <PatientHeader
                    {...{
                        onSort,
                        sortParameter,
                        reverseSort,
                        allCurrentPatientsAreChecked,
                        checkAllPatientToggle,
                    }}
                />
                {_.isEmpty(patients) && !isPatientQueryLoading && (
                    <Flex item container center padding={20} style={{ height: "50vh" }}>
                        <Typography
                            style={{
                                fontSize: 21,
                                opacity: 0.7,
                                lineHeight: "14px",
                                textAlign: "center",
                                whiteSpace: "pre-line",
                            }}
                        >
                            {translate(`global.no-patients-yet${isManager(user) ? "-doctor" : ""}`)}
                        </Typography>
                    </Flex>
                )}
                {isPatientQueryLoading && _.isEmpty(patients) && (
                    <div
                        style={{
                            marginLeft: "70px",
                            marginRight: "10px",
                            border: "1px solid rgb(210, 210, 210)",
                        }}
                    >
                        {Array.from({ length: 20 }).map((_, index) => (
                            <div key={index} style={{ borderBottom: "1px solid rgb(210, 210, 210)" }}>
                                <Skeleton variant="rect" height={"40px"} />
                            </div>
                        ))}
                    </div>
                )}
                {!_.isEmpty(patients) && (
                    <PatientListWindow
                        {...{
                            patients: orderedPatients,
                            selectedPrograms,
                            toggleProgram,
                            selectPatient,
                            refresh: refetch,
                            checkedPatientIds,
                            checkPatient,
                            handleOnRightClick,
                        }}
                    />
                )}

                {isNewPatientDialogOpen && <NewPatientDialog cancel={toggleNewPatientDialog} />}

                {!!x && !!y && (
                    <PatientRowContextMenu
                        {...{
                            x,
                            y,
                            resetPosition,
                            toggleNewProgramDialog,
                            checkedPatientIds,
                            selectedCohortId,
                        }}
                    />
                )}
                {isNewProgramDialogOpen && (
                    <NewProgramDialog
                        {...{
                            patientList: patients
                                .filter((item) => checkedPatientIds.includes(item.patientId))
                                .map((item) => ({
                                    patientId: item.patientId,
                                    programNames: item.programsData
                                        .filter(
                                            (program) =>
                                                program.suspendedParticipation === null ||
                                                program.suspendedParticipation === false,
                                        )
                                        .map((program) => program.shortName),
                                })),
                            cancel: toggleNewProgramDialog,
                            refetch,
                        }}
                    />
                )}
            </Flex>
        </>
    );
};

export default PatientList;
