import {makeStyles} from "@material-ui/core/styles";
import CustomYearPicker from "./CustomYearPicker";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    calendarYearMonthInput: {
        minWidth: "160px",
        maxWidth: "160px",
    },
}));

const CustomMonthPicker = ({label, ...props}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [focus, setFocus] = useState(false);
    const [inputRef, setInputRef] = useState(useRef(null));
    return <CustomYearPicker
            fullWidth
            format={focus ? "MM YYYY" : "MMM YYYY"}
            views={["year", "month"]}
            openTo={'year'}
            classes={{ root: classes.calendarYearMonthInput }}
            placeholder={" "}
            {...(label === '' ? {label: ''} : label ? {label} : {label: t('anamnesis.when')})}
            {...props}
            InputProps={{
                ...props.InputProps,
                inputRef: ref => {
                    if(focus && ref && ref !== inputRef) {
                        ref?.focus();
                        ref?.setSelectionRange(0,0);
                    }
                    setInputRef(ref);
                }
            }}
            InputLabelProps={{
                shrink: true,
                ...props.InputLabelProps
            }}
            onFocus={event=>{
                props.onFocus && props.onFocus(event);
                setFocus(true);
            }}
            onBlur={event=> {
                props.onBlur && props.onBlur(event);
                setFocus(false);
            }}
    />;
};

export default CustomMonthPicker;
