import React, { Component } from "react";
import DatePicker from "components/calendar/DatePicker";

export class CaseDatePicker extends Component {
    state = {
        date: null,
    };

    render() {
        const { isEditDisabled, name, label, ...other } = this.props;
        const date = this.state.date !== null ? this.state.date : this.props.date;
        return (
            <DatePicker
                {...{
                    value: date || null,
                    name: name,
                    label: label,
                    onChange: (dateString) =>
                        this.props.updateParent({
                            [this.props.name]: dateString,
                        }),
                    disable: isEditDisabled,
                    returnLocalDateStringOnChange: true,
                    ...other,
                }}
            />
        );
    }
}

export default CaseDatePicker;
