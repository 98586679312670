import React, {Component} from 'react';
import {MenuItem, Typography, IconButton} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import EmailManager from 'scenes/case/edit/component/confirmation/EmailManager';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import connector from './CaseContactPerson.contact';
import {isCaseManager} from 'domain/User.model';
import Accordion from 'components/grid/Accordion';
import MultiInput from 'components/input/MultiInput';
import _ from 'lodash';
import {emptyCaseContactPerson} from 'domain/CaseContactPerson';
import ConfirmationDialog from 'components/confirmation-dialog/ConfirmationDialog';
import {Delete as DeleteIcon} from "@material-ui/icons";
import {CONTACT_PERSON} from 'domain/User.model';
import MultiInputEmailAddressEditor from 'scenes/user-manager/access-manager/MultiInputEmailAddressEditor';

export class CaseContactPerson extends Component {

    state = {
        caseContactPerson: emptyCaseContactPerson(),
        isDeleteDialogOpen: false
    }

    componentDidMount() {
        if (this.props.caseContactPerson) {
            this.setState({caseContactPerson: this.props.caseContactPerson});
        }
    }

    componentWillUnmount() {
        if (!this.state.isDeleteDialogOpen) {
            this.delayedSave.flush();
        }
    }

    save = () => {
        if (isCaseManager(this.props.user)) {
            this.props.updateUserDetails(this.state.caseContactPerson).then(() => {
                this.props.fetchCase(this.props.case.businessId);
            });
        }
    }

    delayedSave = _.debounce(this.save, 2000);

    onChange = event => {
        this.setState({
            caseContactPerson: {
                ...this.state.caseContactPerson,
                [event.target.name]: event.target.value
            }
        }, () => this.delayedSave())
    }

    handlePhoneNumbersChange = (props) => {
        this.onChange({
            target: {
                name: 'phoneNumbers',
                value: props.phoneNumbers
            }
        });
    }
    handleEmailAddressesChange = (props) => {
        this.onChange({
            target: {
                name: 'emailAddresses',
                value: props.emailAddresses
            }
        });
    }

    toggleDeleteDialog = () => {
        this.setState({
            isDeleteDialogOpen: !this.state.isDeleteDialogOpen
        })
    }

    deleteUserDetails = () => {
        this.props.deleteUserDetails(this.state.caseContactPerson.id)
                .then(() => this.props.fetchCase(this.props.case.businessId));
    }

    render() {
        const {
            t: translate, configuration, user, accordionOpen, setAccordionOpen, refreshCase, excludedUserIds, caseId
        } = this.props;
        const {
            comment, salutation, title, givenName, familyName, phoneNumbers, emailAddresses, street, zipCode, city,
            state, country, userEmailAddress
        } = this.state.caseContactPerson;
        const {
            onChange, handlePhoneNumbersChange, handleEmailAddressesChange, toggleDeleteDialog, deleteUserDetails, save
        } = this;
        const isEditDisabled = !isCaseManager(user);

        return <Accordion
                accordionOpen={accordionOpen}
                setAccordionOpen={setAccordionOpen}
                titleComponent={
                    <Flex item container justifyContent={'space-between'}>
                        <Typography style={{fontWeight: 'bold'}}>
                            {
                                !(givenName || familyName)
                                        ? translate('clinic.contact-person')
                                        : ""
                            }
                            {givenName + ' '}{familyName}
                        </Typography>
                        <Typography style={{fontWeight: 'bold'}}>{comment}</Typography>
                    </Flex>
                }
        >
            <Flex item container column>
                <Flex item container style={{padding: '7px 0px'}}>

                    <EmailManager isEditDisabled={isEditDisabled}
                                  saveCase={save}
                                  personType={CONTACT_PERSON}
                                  person={this.state.caseContactPerson}
                    />
                    <IconButton style={{padding: 0, marginLeft: 20}} onClick={toggleDeleteDialog}
                                disabled={!!this.state.caseContactPerson.userBusinessId}>
                        <DeleteIcon style={{fontSize: 28}}/>
                    </IconButton>
                </Flex>
                <DefaultTextField value={comment}
                                  name={'comment'}
                                  disabled={isEditDisabled}
                                  label={translate('global.comment')}
                                  onChange={onChange}/>
                <Flex item container>
                    <DefaultTextField label={translate("case.salutation")}
                                      style={{width: '100%'}}
                                      select
                                      disabled={isEditDisabled}
                                      SelectProps={{
                                          value: salutation || '',
                                          name: 'salutation',
                                          onChange
                                      }}>
                        {configuration.salutations && configuration.salutations.map(salutation => (
                                <MenuItem key={salutation} value={salutation}>
                                    {translate(`global.${salutation}`)}
                                </MenuItem>
                        ))}
                    </DefaultTextField>
                    <DefaultTextField value={title}
                                      name={'title'}
                                      style={{width: '100%'}}
                                      label={translate("case.title")}
                                      disabled={isEditDisabled}
                                      onChange={onChange}/>
                </Flex>

                <DefaultTextField value={givenName || ''}
                                  label={translate("case.first-name")}
                                  disabled={isEditDisabled}
                                  name={'givenName'}
                                  onChange={onChange}/>

                <DefaultTextField label={translate("case.last-name")}
                                  value={familyName || ''}
                                  onChange={onChange}
                                  disabled={isEditDisabled}
                                  name={'familyName'}/>

                <Flex item container column>
                    <MultiInput {...{
                        inputValues: phoneNumbers,
                        translate,
                        disabled: isEditDisabled,
                        name: 'phoneNumbers',
                        updateInputValues: handlePhoneNumbersChange
                    }}/>
                </Flex>
                <Flex item container column>
                    {
                        userEmailAddress &&
                        <DefaultTextField value={userEmailAddress || ''}
                                          label={translate("global.user-email-address")}
                                          disabled
                                          onChange={() => {
                                          }}/>
                    }
                    <MultiInputEmailAddressEditor {...{
                        //TODO LM: Case is present here because i have no better idea, the contact person itself will never see this
                        entityIdToAssign: caseId,
                        emailAddresses: emailAddresses,
                        invitedRoleType: CONTACT_PERSON,
                        person: this.state.caseContactPerson,
                        disabled: isEditDisabled,
                        updateInputValues: handleEmailAddressesChange,
                        refresh: refreshCase
                    }}/>
                </Flex>

                <DefaultTextField label={translate("case.street")}
                                  value={street || ''}
                                  onChange={onChange}
                                  disabled={isEditDisabled}
                                  name={'street'}/>

                <DefaultTextField label={translate("case.zip")}
                                  value={zipCode || ''}
                                  onChange={onChange}
                                  disabled={isEditDisabled}
                                  name={'zipCode'}/>

                <DefaultTextField label={translate("case.city")}
                                  value={city || ''}
                                  onChange={onChange}
                                  disabled={isEditDisabled}
                                  name={'city'}/>

                <DefaultTextField label={translate("case.state")}
                                  select
                                  disabled={isEditDisabled}
                                  SelectProps={{
                                      value: state || '',
                                      onChange: onChange,
                                      name: 'state'
                                  }}>
                    {configuration.states && configuration.states.map(stateInArray => (
                            <MenuItem key={stateInArray} value={stateInArray}>
                                {stateInArray}
                            </MenuItem>
                    ))}
                </DefaultTextField>

                <DefaultTextField label={translate("case.country")}
                                  select
                                  disabled={isEditDisabled}
                                  SelectProps={{
                                      value: country || '',
                                      onChange: onChange,
                                      name: 'country'
                                  }}>
                    {configuration.countries && configuration.countries.map(countryInArray => (
                            <MenuItem key={countryInArray} value={countryInArray}>
                                {countryInArray}
                            </MenuItem>
                    ))}
                </DefaultTextField>

            </Flex>
            <ConfirmationDialog {...{
                dialogOpen: this.state.isDeleteDialogOpen,
                onDialogClose: toggleDeleteDialog,
                onConfirm: deleteUserDetails,
                confirmationTextKey: "case.delete-contact"
            }}/>
        </Accordion>;
    }
}

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(withTranslation()(CaseContactPerson));
