import React, {Component} from 'react';
import Flex from 'components/grid/Flex';
import {Typography} from "@material-ui/core";
import utils from 'utils/Utils';
import Tag from 'components/tags/Tag';

const Cell = (props) => {
    const {text, bold, transparent, item, color, style, center = true, ...other} = props;
    return (
            <Flex item={item || '1 0 5%'} container center={center} style={style || {width: '5%'}} {...other} >
                <Typography style={{
                    color: color || '#030303',
                    opacity: transparent ? 0.6 : 1,
                    bold: bold ? 'bold' : 'unset',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}>{text}</Typography>
            </Flex>
    );
};

export class CareProviderRow extends Component {

    render() {
        const {careProvider, selectCareProvider, selectedPrograms, toggleProgram } = this.props;
        const {name, city} = careProvider;
        const programs = utils.toArray(careProvider.programs, ",");
        return (
                <Flex data-testid={'cp:' + careProvider.id} item grow={0} container style={{
                    border: '1px solid #E2E1E4',
                    borderTop: 'unset',
                    height: 40
                }}
                      onClick={selectCareProvider(careProvider)}>
                    <Cell {...{text: name}}/>
                    <Flex item={'1 0 5%'} container center style={{width: '5%'}}>
                        {
                            (programs || []).map(program => (
                                    <Tag key={program} {...{
                                        selected: selectedPrograms?.includes(program),
                                        tag: program,
                                        toggleSelection: toggleProgram
                                    }}/>
                            ))
                        }
                    </Flex>

                    <Cell {...{text: city}}/>
                </Flex>
        );
    }
}

export default CareProviderRow;
