export const WidgetType = {
    DROPDOWN: "dropdown",
    RANGE_FILTER: "rangeFilter",
    SWITCH: "switch",
    SWITCH_WITH_SELECT: "switchWithSelect",
    SWITCH_WITH_SELECT_AND_TAGS: "switchWithSelectAndTags",
    TAGS: "tags",
    SWITCH_WITH_AUTOCOMPLETE: "switchWithAutocomplete",
    SWITCH_WITH_YEAR_SELECTOR: "switchWithYearSelector",
    SIMPLE_NUMBER: "simpleNumber",
    AUTOCOMPLETE: "autocomplete",
    TERM: "term",
    NESTED: "nested",
    NUMBER_OR_SELECT: "numberOrSelect",
    NUMBER_RANGE: "numberRange",
}
