import { makeStyles } from "@material-ui/core/styles";
import Flex from "components/grid/Flex";
import { Dialog, IconButton, Button, Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ContactMail from "@material-ui/icons/ContactMail";
import { useState } from "react";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import HmoFilledButton from "components/button/HmoFilledButton";
import ConfirmationDialog from "components/confirmation-dialog/ConfirmationDialog";
import Title from "components/title/Title";
import { htmlRed, grey, htmlLightGrey } from "components/colors/Colors";
import { useFetchProgramTemplatesQuery, useConnectCaseToProgramMutation } from "scenes/patient/ProgramApi";
import { Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { PATIENT_PAGE_PROGRAM } from "routes/routes";
import { CARE_PROVIDER } from "domain/EntityAuthorizationType.model";
import { useGetCareProvidersQuery } from "scenes/patient/CareProviderApi";

const useStyles = makeStyles((theme) => ({
    cancelButton: {
        fontSize: 13,
        textTransform: "unset",
        color: htmlLightGrey,
    },
}));

const ConnectionDialog = ({ refreshCase, caseId }) => {
    const classes = useStyles();
    const { t: translate } = useTranslation();
    const [open, setOpen] = useState(false);
    const toggleOpen = () => setOpen(!open);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const selectTemplate = (event, template) => setSelectedTemplate(template);
    const [connectConfirmationDialogOpen, setConnectConfirmationDialogOpen] = useState(false);
    const toggleConnectConfirmationDialog = () => setConnectConfirmationDialogOpen(!connectConfirmationDialogOpen);
    const { data: careProviders = [] } = useGetCareProvidersQuery();
    const hmoCareProviderId = careProviders.find((cp) => cp.name?.toLowerCase()?.includes("hmo"))?.id;
    const { data: programTemplates } = useFetchProgramTemplatesQuery(CARE_PROVIDER);
    const hmoProgramTemplates = programTemplates?.filter((pt) => pt.careProviderId === hmoCareProviderId);
    const [connectCaseToProgram] = useConnectCaseToProgramMutation();
    const { enqueueSnackbar } = useSnackbar();

    const submitConnectCaseToProgram = () => {
        connectCaseToProgram({ caseId, programTemplateId: selectedTemplate.id })
            .unwrap()
            .then(() => {
                enqueueSnackbar(translate("global.data.saved.success"), {
                    variant: "success",
                    anchorOrigin: { horizontal: "center", vertical: "bottom" },
                    autoHideDuration: 1500,
                });
                toggleOpen();
                refreshCase();
            })
            .catch(() => {
                enqueueSnackbar(translate("global.data.saved.error"), {
                    variant: "error",
                    anchorOrigin: { horizontal: "center", vertical: "bottom" },
                    autoHideDuration: 1500,
                });
                toggleOpen();
            });
    };

    const getOptionLabel = (item) => (item?.id ? item.name || `N/A - ID: ${item.id}` : "");

    return (
        <>
            <Tooltip title={translate("global.open-care-program-for-case")}>
                <IconButton onClick={toggleOpen} style={{ padding: 3 }}>
                    <ContactMail style={{ fill: "white" }} />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={toggleOpen} maxWidth="sm" fullWidth>
                <Flex item container direction={"column"} padding={20}>
                    <Flex
                        item
                        container
                        style={{ paddingBottom: 10, borderBottom: `1px solid ${grey}` }}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                    >
                        <Flex item container alignItems={"center"}>
                            <Title style={{ color: htmlRed }}>{translate("global.connect-case-to-program")}</Title>
                        </Flex>
                        <Flex item container justifyContent={"flex-end"}>
                            <Button onClick={toggleOpen} className={classes.cancelButton}>
                                {translate("global.cancel")}
                            </Button>

                            <HmoFilledButton disabled={!selectedTemplate} onClick={toggleConnectConfirmationDialog}>
                                {translate("global.connect")}
                            </HmoFilledButton>
                        </Flex>
                    </Flex>
                    <Flex item container column>
                        <Autocomplete
                            options={hmoProgramTemplates || []}
                            onChange={selectTemplate}
                            name={"selectedTemplate"}
                            value={selectedTemplate || ""}
                            getOptionSelected={(option, value) => {
                                return option.id === value.id;
                            }}
                            style={{ marginBottom: 10 }}
                            getOptionLabel={getOptionLabel}
                            renderOption={getOptionLabel}
                            renderInput={(params) => (
                                <DefaultTextField {...params} label={translate("global.program-template")} />
                            )}
                        />
                    </Flex>
                </Flex>
                <ConfirmationDialog
                    {...{
                        dialogOpen: connectConfirmationDialogOpen,
                        onDialogClose: toggleConnectConfirmationDialog,
                        onConfirm: submitConnectCaseToProgram,
                        confirmationTextKey: "global.connect-case-to-program-confirmation",
                    }}
                />
            </Dialog>
        </>
    );
};

const ConnectCaseToProgram = ({ patientId, caseId, careProgramId, refreshCase, translate }) => {
    return careProgramId ? (
        <Tooltip title={translate("global.goto-care-program")}>
            <IconButton
                component={Link}
                to={PATIENT_PAGE_PROGRAM.pathWithId(patientId, careProgramId)}
                style={{ padding: 3 }}
            >
                <ContactMail style={{ fill: "white" }} />
            </IconButton>
        </Tooltip>
    ) : (
        <ConnectionDialog {...{ refreshCase, caseId }} />
    );
};

export default ConnectCaseToProgram;
