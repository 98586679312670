import HMOApi from "store/api/HMOApi";

export const UserDetailAccessApi = HMOApi.injectEndpoints({
    endpoints: (build) => ({
        fetchExistingPersons: build.query({
            query: (emailAddress) => ({ url: `existing-persons/${emailAddress}` }),
        }),
        getAccessesOffered: build.query({
            query: ({ patientId, personUserDetailsId, role }) => ({
                url: `access-offered`,
                method: "post",
                data: { patientId, personUserDetailsId, role },
            }),
        }),
        getIsPermissionGranted: build.query({
            query: ({ userDetailsId, permissionType }) => ({
                url: `permissions/${userDetailsId}/${permissionType}/exists`,
            }),
            providesTags: (result, error, { userDetailsId, permissionType }) => [
                { type: "PERMISSION", id: `${userDetailsId}-${permissionType}` },
            ],
        }),

        grantPermission: build.mutation({
            query: ({ userDetailsId, permissionType }) => ({
                method: "POST",
                url: `permissions/${userDetailsId}/${permissionType}`,
            }),
            invalidatesTags: (result, error, { userDetailsId, permissionType }) => [
                { type: "PERMISSION", id: `${userDetailsId}-${permissionType}` },
            ],
        }),

        removePermission: build.mutation({
            query: ({ userDetailsId, permissionType }) => ({
                method: "DELETE",
                url: `permissions/${userDetailsId}/${permissionType}`,
            }),
            invalidatesTags: (result, error, { userDetailsId, permissionType }) => [
                { type: "PERMISSION", id: `${userDetailsId}-${permissionType}` },
            ],
        }),
        assignPatientToExitingUser: build.mutation({
            query: ({ grantType, existingUserId, currentlyEditedUserDetailId }) => ({
                url: `grant-access/manager-assigns-patient-to-existing-user`,
                method: "post",
                data: { grantType, existingUserId, currentlyEditedUserDetailId },
            }),
        }),
        assignCaseContactTo: build.mutation({
            query: ({
                grantType,
                existingUserId,
                existingUserDetailsId,
                currentlyEditedUserDetailId,
                entityIdToAssign,
            }) => ({
                url: `grant-access/manager-assigns-case-contact`,
                method: "post",
                data: {
                    grantType,
                    existingUserId,
                    existingUserDetailsId,
                    currentlyEditedUserDetailId,
                    entityIdToAssign,
                },
            }),
        }),
        assignCareProviderContactTo: build.mutation({
            query: ({
                grantType,
                existingUserId,
                currentlyEditedUserDetailId,
                currentlyEditedContactType,
                entityIdToAssign,
                entityType,
            }) => ({
                url: `grant-access/manager-assigns-care-provider-contact`,
                method: "post",
                data: {
                    grantType,
                    existingUserId,
                    currentlyEditedUserDetailId,
                    currentlyEditedContactType,
                    entityIdToAssign,
                    entityType,
                },
            }),
        }),
        grantPatientAccessToContactPerson: build.mutation({
            query: ({ grantType, existingUserId, currentlyEditedUserDetailId, accessInputs }) => ({
                url: `grant-access/assign-patient-access-to-contact`,
                method: "post",
                data: { grantType, existingUserId, currentlyEditedUserDetailId, accessInputs },
            }),
        }),
        invite: build.mutation({
            query: (accessInputs) => ({ url: `invite`, method: "post", data: accessInputs }),
        }),
    }),
});

export const {
    useFetchExistingPersonsQuery,
    useAssignPatientToExitingUserMutation,
    useAssignCaseContactToMutation,
    useAssignCareProviderContactToMutation,
    useGrantPatientAccessToContactPersonMutation,
    useGetAccessesOfferedQuery,
    useLazyGetAccessesOfferedQuery,
    useInviteMutation,
    useGetIsPermissionGrantedQuery,
    useGrantPermissionMutation,
    useRemovePermissionMutation,
} = UserDetailAccessApi;
