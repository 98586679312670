import { inputRendererMap } from "../../../filter-configs/inputRendererMap";
export const FilterWidgetRenderer = ({widgetType, ...props}) => {
    const Renderer = inputRendererMap[widgetType];
    if (!Renderer) {
        console.warn(`No renderer found for widget type: "${widgetType}".\n` +
            'Please ensure the widget type is correct and that a corresponding renderer is defined in inputRendererMap.js.\n' +
            `Available renderers: [${Object.keys(inputRendererMap)}]`);
        return null;
    }
    return <Renderer {...{widgetType, ...props}} />;
};
