import { AxiosClient } from "../../configs/Axios";
export const downloadCohortAsFile = (cohortId) => {
    return AxiosClient({
        url: "/cohort-excel-download/" + cohortId,
        method: "GET",
        responseType: "blob",
    })
        .then((response) => {
            const title = response.headers["content-disposition"].split(";")[1].split("=")[1];
            const href = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", title);
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
};
